<template lang="pug">
v-layout.recipe-library
  v-navigation-drawer(v-model="showSideBar" :permanent="!floatingSideBar" :absolute="floatingSideBar" :temporary="floatingSideBar")
    .recipe-library__sidebar
      h2.recipe-library__sidebar-title
        | Recipes
        v-btn(icon title="New recipe" to="/recipes/new")
          v-icon mdi-plus

      .recipe-filter
        h4.recipe-filter-title
          v-icon mdi-pencil
          |  Name
        v-text-field(placeholder="recipe name" v-model="libraryFilters.recipeName" hide-details clearable)

      .recipe-filter
        h4.recipe-filter-title
          v-icon mdi-account-multiple
          |  Servings

        v-range-slider.servings-slider(v-model="libraryFilters.servings" min="1" max="20"
                                      thumb-label="always" :thumb-size="24"
                                      hide-details color="teal"
                                      )

      .recipe-filter
        h4.recipe-filter-title
          v-icon mdi-timer
          |  Cooking time
        .filter-choice(v-for="cookingTime in recipeCookingTimes")
          v-checkbox(v-model="libraryFilters.cookingTimes" :value="cookingTime.key" :label="cookingTime.label" hide-details)

      .recipe-filter
        h4.recipe-filter-title
          v-icon mdi-cogs
          |  Complexity
        .filter-choice(v-for="complexity in recipeComplexities")
          v-checkbox(v-model="libraryFilters.complexities" :value="complexity.key" :label="complexity.label" hide-details)

      .recipe-filter
        h4.recipe-filter-title
          v-icon mdi-tag-multiple
          |  Tags
        .filter-choice(v-for="tag in recipeTags")
          v-checkbox(v-model="libraryFilters.recipeTags" :value="tag._id" :label="tag.name" hide-details)

      .recipe-filter
        h4.recipe-filter-title
          v-icon mdi-food-apple
          |  Ingredients
        v-autocomplete(label="filter by ingredient" hide-details multiple clearable hide-selected chips deletable-chips auto-select-first
                      :value="libraryFilterIngredients" :items="ingredients" item-text="name" item-value="_id" :filter="ingredientsFilter"
                      @change="addIngredientFilter")

  .recipe-library__sub-menu
    h2.recipe-library__sub-menu-title
      | Recipes

    v-btn(tile title="New recipe" to="/recipes/new")
      v-icon(left) mdi-plus
      | New Recipe

    v-btn(tile title="Toggle the filters menu" @click="showSideBar = !showSideBar")
      v-icon(left) mdi-filter-outline
      | Filters

  .recipe-library__recipes
    mp-tile-recipe-card(class="recipe-library__recipe-card"
                        v-for="recipe in recipes" :key="recipe._id"
                        :recipe="recipe"
                        :to="`/recipes/${recipe.slug}`"
                        )
</template>

<script>
import { recipeComplexities } from '@/api/models/Recipe'
import MpTileRecipeCard from '@/components/MpTileRecipeCard'
import * as _ from '@/util'

const recipeCookingTimes = [
  {
    key: '-10',
    label: '< 10 min'
  },
  {
    key: '10-30',
    label: '10 min to 30 min'
  },
  {
    key: '30-1h',
    label: '30 min to 1 hour'
  },
  {
    key: '+1h',
    label: '> 1 hour'
  }
]

export default {
  components: {
    MpTileRecipeCard
  },
  data () {
    return {
      showSideBar: null,
      recipeComplexities,
      recipeCookingTimes,
      libraryFilters: {}
    }
  },
  computed: {
    recipes () {
      return this.$store.state.library.recipesLibrary.recipes
    },
    recipeTags () {
      return this.$store.state.library.recipeTags
    },
    ingredients () {
      return this.$store.getters['library/ingredients']
    },
    libraryFilterIngredients () {
      return this.libraryFilters.ingredients.map(ingredientId => {
        return this.ingredients.find(ingredient => ingredient._id === ingredientId)
      })
    },
    floatingSideBar () {
      return this.$vuetify.breakpoint.width < 700
    }
  },
  watch: {
    libraryFilters: {
      handler (filters) {
        this.onLibraryFiltersChange(filters)
      },
      deep: true
    }
  },
  created () {
    this.libraryFilters = _.cloneDeep(this.$store.state.library.recipesLibrary.filters)
    this.onLibraryFiltersChange = _.debounce(_.skipFirst(function (filters) {
      this.$store.commit('library/updateLibraryFilters', filters)
    }), 100)
  },
  methods: {
    ingredientsFilter (item, queryText, itemText) {
      return this.slugFilter(item, queryText)
    },
    slugFilter (item, queryText, itemText) {
      return item.slug.indexOf(_.normalize(queryText)) > -1
    },
    addIngredientFilter (ingredientIds) {
      this.libraryFilters.ingredients = ingredientIds
    }
  }
}
</script>

<style lang="stylus" scoped>
.recipe-library
  display flex
  height 100%
  font-size 1.2em

  @media (max-width 699.99px)
    flex-direction column

  &__sub-menu
    display flex

    @media (min-width 700px)
      display none

  &__sub-menu-title
    font-size 1.2em
    padding 0 .5em
    flex 1

  &__sidebar
    background-color #dce9e4
    overflow-y auto
    height 100%

  &__sidebar-title
    text-align center

  &__recipes
    background-color #f4fffa
    flex 1
    display grid
    overflow-y auto
    grid-template-columns repeat(1, 1fr)
    grid-auto-rows min-content
    font-size .8em

    @media (min-width 360px)
      grid-template-columns repeat(2, 1fr)

    @media (min-width 840px)
      grid-template-columns repeat(3, 1fr)

    @media (min-width 1100px)
      grid-template-columns repeat(4, 1fr)

    @media (min-width 1520px)
      grid-template-columns repeat(5, 1fr)

    @media (min-width 1780px)
      grid-template-columns repeat(6, 1fr)

    @media (min-width 1980px)
      grid-template-columns repeat(7, 1fr)

    @media (min-width 2180px)
      grid-template-columns repeat(8, 1fr)

  &__recipe-card
    height 150px

    @media (min-width 500px)
      height 200px

    @media (min-width 1780px)
      height 240px

.recipe-filter
  padding 0.4em

.recipe-filter-title
  display block

.servings-slider
  margin-top 2em
  margin-left 1em
  margin-right 1em

.filter-choice
  display block
  margin-left 1em

</style>
