<template lang="pug">
.mp-page
  h4.mp-section-title Recipe Tags

  p.
    A recipe can be attached to any number of tags.

  .form
    .mp-row(v-for="recipeTag in recipeTags" :key="recipeTag._id")
      v-text-field(v-if="recipeTag._id === editingRecipeTag._id" v-model="editingRecipeTag.name" :rules="editingRecipeTagNameRules" @keyup.enter="updateRecipeTag()")
      template(v-else)
       | {{ recipeTag.name }}
       v-spacer

      div(v-if="recipeTag._id === editingRecipeTag._id")
        v-btn(icon title="Update recipe tag" @click="updateRecipeTag()")
          v-icon mdi-check
        v-btn(icon title="Cancel" @click="cancelRecipeTagEditing()")
          v-icon mdi-close
      div(v-else)
        v-btn(icon title="Rename recipe tag" @click="startRecipeTagEditing(recipeTag)")
          v-icon mdi-pencil
        v-btn(icon title="Delete recipe tag" @click="deleteRecipeTag(recipeTag)")
          v-icon mdi-delete

    v-form.mp-row(v-model="validNewRecipeTagForm" ref="newRecipeTagForm" @submit.prevent="")
      v-text-field(v-model="newRecipeTag.name" placeholder="New recipe tag" :rules="newRecipeTagNameRules" @keyup.enter="addRecipeTag")
      v-btn(icon title="Create a new recipe tag" @click="addRecipeTag" :disabled="!validNewRecipeTagForm")
        v-icon mdi-plus

</template>

<script>
import { RecipeTag } from '../api/models/RecipeTag'

export default {
  data () {
    return {
      newRecipeTag: null,
      validNewRecipeTagForm: true,
      editingRecipeTag: {},
      editingRecipeTagNameRules: [
        (v) => !!v || 'Name is required',
        (v) => !this.otherRecipeTagSlugs.includes(this.newRecipeTag.slugged) || 'This tag already exists'
      ],
      otherRecipeTagSlugs: [],
      newRecipeTagNameRules: [
        (v) => !!v || 'Name is required',
        (v) => !this.recipeTagsSlugs.includes(this.newRecipeTag.slugged) || 'This tag already exists'
      ]
    }
  },
  computed: {
    recipeTags () {
      return this.$store.state.library.recipeTags
    },
    recipeTagsSlugs () {
      return this.recipeTags.map(c => c.slug)
    }
  },
  created () {
    this.cleanNewRecipeTag()
  },
  methods: {
    startRecipeTagEditing (category) {
      this.editingRecipeTag = category.clone()
      const normalizedName = category.slugged
      this.otherRecipeTagSlugs = this.recipeTagsSlugs.filter(n => n !== normalizedName)
    },
    cancelRecipeTagEditing () {
      this.editingRecipeTag = {}
    },
    cleanNewRecipeTag () {
      this.newRecipeTag = new RecipeTag()
    },
    async addRecipeTag () {
      if (this.$refs.newRecipeTagForm.validate()) {
        await this.$api.addDocument(this.newRecipeTag)
        this.$refs.newRecipeTagForm.resetValidation()
        this.cleanNewRecipeTag()
      }
    },
    async updateRecipeTag () {
      await this.$api.updateDocument(this.editingRecipeTag)
      this.editingRecipeTag = {}
    },
    async deleteRecipeTag (recipeTag) {
      const message = 'Are you sure?\nDeleting the category will remove it from the recipes using it.'
      this.$ui.confirm(message, async () => {
        await this.$api.deleteDocument(recipeTag)
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.form
  max-width 340px

.category
  // display flex
  // align-items center

  &__name
    flex 1
</style>
