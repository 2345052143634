import * as _ from '@/util'

export const ThingsToBuyKey = 'thingstobuy'

export const ThingsToBuy = class ThingsToBuy {
  /*
  properties:
    items: []string
  */
  constructor (data = {}) {
    this._id = ThingsToBuyKey
    this._rev = data._rev
    this.items = _.cloneDeep(data.items) || []
  }

  toPouchDBFormat () {
    return {
      _id: ThingsToBuyKey,
      _rev: this._rev,
      items: this.items
    }
  }

  clone () {
    return new this.constructor(this)
  }
}
