<template lang="pug">
.mp-page.mp-page--small
  h4.mp-section-title Things to buy

  p.
    These items will appear on the next shopping list.

  .category(v-for="(item, index) in thingsToBuy.items" :key="index")
    .category__name {{ item }}
    v-btn(icon title="Delete item" @click="deleteItem(index)")
      v-icon mdi-delete

  v-form.mp-row(v-model="validNewItemForm" ref="newItemForm" @submit.prevent="addItem")
    v-text-field(v-model="newItem" placeholder="New item" :rules="newItemRules")
    v-btn(type="submit" icon title="Add a new item" :disabled="!validNewItemForm")
      v-icon mdi-plus
</template>

<script>
export default {
  name: 'ThingsToBuy',
  data () {
    return {
      newItem: '',
      validNewItemForm: true,
      newItemRules: [
        (v) => !!v || 'Name is required'
      ]
    }
  },
  computed: {
    thingsToBuy () {
      return this.$store.state.library.thingsToBuy
    }
  },
  methods: {
    async addItem () {
      const newThingsToBuy = this.thingsToBuy.clone()
      newThingsToBuy.items.push(this.newItem)
      this.newItem = ''
      await this.$api.updateThingsToBuy(newThingsToBuy)
      this.$refs.newItemForm.resetValidation()
    },
    async deleteItem (itemIndex) {
      const newThingsToBuy = this.thingsToBuy.clone()
      newThingsToBuy.items.splice(itemIndex, 1)
      await this.$api.updateThingsToBuy(newThingsToBuy)
    }
  }
}
</script>

<style lang="stylus">
</style>
