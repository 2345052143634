<template lang="pug">
v-app
  v-main.login__panelwrapper
    v-container.login__panel(elevation-24)
      h1.login__title Create your Meal Planner account
      v-form(v-model="valid" method="post" @submit.prevent="register(email, password)")
        v-text-field(label="Email" v-model="email" :rules="emailRules" required)
        v-text-field(type="password" label="Password" v-model="password" :rules="passwordRules" required)
        v-btn(type="submit" block color="primary" :disabled="!valid") Register

    .login__createaccountlink
      | Already have an account?&nbsp;
      router-link(to="/login") Log in
</template>

<script>

export default {
  data () {
    return {
      valid: false,
      email: '',
      password: '',
      emailRules: [
        (v) => !!v || 'E-mail is required'
      ],
      passwordRules: [
        (v) => !!v || 'Password is required'
      ]
    }
  },
  methods: {
    async register (email, password) {
      await this.$api.register({ email, password })
      this.$ui.success('Account created successfully. Now please login')
      await this.$router.push('/login')
    }
  }
}
</script>

<style lang="stylus">

.login

  &__panelwrapper
    align-items: center

  &__panel
    text-align: center
    max-width: 400px

  &__title
    font-size: 2em

  &__createaccountlink
    margin-top: 2em
    font-size: 0.9em
    text-align: center

</style>
