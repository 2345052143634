import Vue from 'vue'
import Router from 'vue-router'

import MpRecipe from '@/components/MpRecipe'
import About from '@/views/About'
import AuthLayout from '@/views/AuthLayout'
import IngredientCategories from '@/views/IngredientCategories'
import IngredientEdit from '@/views/IngredientEdit'
import IngredientLibrary from '@/views/IngredientLibrary'
import Login from '@/views/Login'
import Page404 from '@/views/Page404'
import Planning from '@/views/Planning'
import PlanningDay from '@/views/PlanningDay'
import Preferences from '@/views/Preferences'
import RecipeLibrary from '@/views/RecipeLibrary'
import RecipeRoot from '@/views/RecipeRoot'
import Register from '@/views/Register'
import ShoppingList from '@/views/ShoppingList'
import ThingsToBuy from '@/views/ThingsToBuy'

Vue.use(Router)

export function createRouter (api) {
  const requireAuthenticatedUser = (to, from, next) => {
    if (api.authenticate()) {
      next()
    } else {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    }
  }

  const redirectAuthenticatedUserToApp = (to, from, next) => {
    if (api.authenticate()) {
      next({
        path: '/'
      })
    } else {
      next()
    }
  }

  return new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
      {
        path: '/login',
        component: Login,
        beforeEnter: redirectAuthenticatedUserToApp
      },
      {
        path: '/register',
        component: Register,
        beforeEnter: redirectAuthenticatedUserToApp
      },
      {
        path: '/',
        component: AuthLayout,
        beforeEnter: requireAuthenticatedUser,
        children: [
          {
            path: '',
            redirect: '/planning'
          },
          {
            path: 'planning',
            component: Planning,
            // en attendant les alias dynamiques, on utilise les propriétés dynamiques
            // https://github.com/vuejs/vue-router/issues/1858
            props: () => ({
              year: new Date().toISOString().substr(0, 4),
              month: new Date().toISOString().substr(5, 2)
            }),
            children: [
              {
                path: 'shoppinglist/saved',
                component: ShoppingList,
                props: {
                  loadSaved: true
                }
              },
              {
                path: 'shoppinglist/:start(\\d{4}-\\d{2}-\\d{2})/:end(\\d{4}-\\d{2}-\\d{2})',
                component: ShoppingList,
                props: true
              }
            ]
          },
          {
            path: 'planning/:year(\\d{4})-:month(\\d{2})',
            name: 'planning-month',
            component: Planning,
            props: true
          },
          {
            path: 'planning/:date(\\d{4}-\\d{2}-\\d{2})',
            component: PlanningDay,
            props: true
          },
          {
            path: 'preferences',
            component: Preferences
          },
          {
            path: 'categories',
            component: IngredientCategories
          },
          {
            path: 'ingredients',
            component: IngredientLibrary
          },
          {
            path: 'ingredients/new',
            component: IngredientEdit
          },
          {
            path: 'ingredients/:ingredientSlug',
            component: IngredientEdit,
            props: true
          },
          {
            path: 'recipes',
            component: RecipeLibrary
          },
          {
            path: 'recipes/new',
            component: MpRecipe,
            props: {
              edit: true
            }
          },
          {
            path: 'recipes/:recipeSlug',
            component: RecipeRoot,
            props: true,
            children: [
              {
                path: '',
                component: MpRecipe
              },
              {
                path: 'edit',
                component: MpRecipe,
                props: {
                  edit: true
                }
              },
              {
                path: 'cook',
                component: MpRecipe,
                props: {
                  cook: true
                }
              }
            ]
          },
          {
            path: 'thingstobuy',
            component: ThingsToBuy
          },
          {
            path: 'about',
            component: About
          }
        ]
      },
      {
        path: '*',
        component: Page404
      }
    ]
  })
}
