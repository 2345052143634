<template lang="pug">
v-btn(v-bind="$attrs" v-on="$listeners" @click="executeAction" :disabled="loading || disableif" :loading="loading")
  slot
</template>

<script>
export default {
  props: {
    action: {
      type: Function,
      required: true
    },
    disableif: {
      type: Boolean
    }
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    async executeAction () {
      this.loading = true
      try {
        await this.action()
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
