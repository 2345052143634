<template lang="pug">
.recipe-link

  a.recipe-link__toggle-player(v-if="youtubeVideoId" @click="togglePlayer" title="Open/close player")
    v-icon mdi-youtube
    |  {{ url }}
    iframe(v-if="playerActive" :src="sourceURL" width="560" height="315" frameborder="1" allowfullscreen)

  a(v-else :href="url" target="_blank")
    v-icon mdi-link
    |  {{ url }}
</template>

<script>
// documentation API player youtube
// https://developers.google.com/youtube/player_parameters?hl=fr#IFrame_Player_API

const youtubeWatchExtract = /watch\?v=(\w+)/
const youtubeExtract = /youtu\.be\/(\w+)/

export default {
  props: {
    url: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      playerActive: false,
      youtubeVideoId: null
    }
  },
  computed: {
    sourceURL: function () {
      return 'https://www.youtube.com/embed/' + this.youtubeVideoId + '?rel=0&showinfo=0'
    }
  },
  created () {
    const isYoutubeVideo = this.url.match(youtubeWatchExtract) || this.url.match(youtubeExtract)
    if (isYoutubeVideo) {
      this.youtubeVideoId = isYoutubeVideo[1]
    }
  },
  methods: {
    togglePlayer: function () {
      this.playerActive = !this.playerActive
    }
  }
}
</script>

<style lang="stylus">
.recipe-link
  display inline

.recipe-link__toggle-player
  cursor pointer
</style>
