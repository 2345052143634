<template lang="pug">
.recipe-card(:style="{'background-image': `url(${photoURI})`}")
  .recipe-card__title(v-html="recipe.name")
</template>

<script>
import { cacheGet } from '@/api/attachment-url-cache'

const defaultRecipePhoto = '/img/empty_plate.png'

export default {
  props: {
    recipe: {
      type: Object,
      required: true
    },
    draggable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    photoURI () {
      return this.recipe._attachments.photo ? cacheGet(this.recipe._attachments.photo) : defaultRecipePhoto
    }
  }
}
</script>

<style lang="stylus" scoped>
.recipe-card
  display inline-block
  box-shadow 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)
  position relative
  background-position 50%
  background-size cover
  transition box-shadow .3s

  &:hover
    box-shadow 0px 7px 8px -4px rgba(0,0,0,0.2), 0px 12px 17px 2px rgba(0,0,0,0.14), 0px 5px 22px 4px rgba(0,0,0,0.12) !important

  &__title
    position absolute
    bottom 0
    left 0
    right 0
    padding .3em .5em
    background-color white
    font-weight 600
    word-break break-word
</style>
