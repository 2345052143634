const logLevels = {
  debug: 4,
  info: 3,
  warn: 2,
  error: 1,
  silent: 0
}

export default class Logger {
  constructor (options) {
    this.backend = console.log // eslint-disable-line no-console
    this.silent = false
    if (options) {
      if (options.backend !== undefined) {
        this.backend = options.backend
      }
      if (options.silent !== undefined) {
        this.silent = options.silent
      }
    }
    this.setLevel('debug')
  }

  setLevel (level) {
    this.level = level
    this.updateLoggers()
  }

  updateLoggers () {
    const currentLevelValue = logLevels[this.level]
    Object.entries(logLevels).forEach(([level, value]) => {
      this[level] = value <= currentLevelValue
        ? this.backend.bind(this, `MP-${level.toUpperCase()}`)
        : () => {}
    })
  }
}
