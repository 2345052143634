import { Entity } from './Entity'

export const IngredientCategory = class IngredientCategory extends Entity {
  /*
  properties:
    name: string

  relations:
    ingredients: []Ingredient

  */
  static fields () {
    return {
      name: '',
      order: 0
    }
  }

  get kind () {
    return 'IngredientCategory'
  }

  destroy (state) {
    return state.ingredients
      .filter(ingredient => ingredient.categoryId === this._id)
      .map(ingredient => ingredient.clone().resetCategory())
  }
}
