export const StateValid = 'token_valid'
export const StateExpired = 'token_expired'
export const StateInvalid = 'token_invalid'
export const StateEmpty = 'token_empty'

const getters = {
  authenticated (state) {
    return state.state === StateValid
  }
}

const mutations = {
  login (state, { email, token }) {
    state.email = email
    state.token = token
    state.state = StateValid
    localStorage.setItem('mp-authentication', JSON.stringify({
      email,
      token
    }))
  },
  logout (state) {
    state.email = ''
    state.token = ''
    state.state = StateEmpty
    localStorage.removeItem('mp-authentication')
  },
  setOnline (state, online) {
    state.online = online
  }
}

export function buildAccountStore (initialStateDump) {
  return {
    namespaced: true,
    state: {
      email: initialStateDump.email || '',
      token: initialStateDump.token || '',
      state: initialStateDump.token ? StateValid : StateEmpty,
      online: initialStateDump.online
    },
    getters,
    mutations
  }
}
