import slugifyExt from '@sindresorhus/slugify'

export function normalize (string) {
  return string == null ? '' : string.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export function slugify (string) {
  return slugifyExt(string || '', { decamelize: false })
}

export function capitalize (string) {
  return string == null || string === '' ? '' : string.charAt(0).toUpperCase() + string.substring(1)
}

export function dataURIToBlob (dataURI) {
  const arr = dataURI.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  return new Blob([atob(arr[1])], { type: mime })
}

// _.sortBy ne respecte pas la locale pour trier les accents a à e é è
export const localeStringComparator = Intl.Collator().compare

export function getISODate (date) {
  return date ? date.toISOString().substring(0, 10) : ''
}

export async function sleep (durationMs) {
  return new Promise(resolve => setTimeout(resolve, durationMs))
}

// version allégée de debounce https://github.com/lodash/lodash/blob/master/debounce.js
export function debounce (func, wait) {
  let lastThis
  let lastArgs
  let timerId
  function wrapper (...args) {
    lastThis = this
    lastArgs = args
    if (timerId) {
      clearTimeout(timerId)
    }
    timerId = setTimeout(invoke, wait)
  }
  function invoke () {
    timerId = null
    func.apply(lastThis, lastArgs)
  }
  return wrapper
}

/*
  Clone récursivement un objet, de sorte que la modification de l'objet
  source n'impacte pas l'objet cloné.
*/
export function cloneDeep (source) {
  const objectType = typeof source
  if (objectType === 'string' || objectType === 'number' || objectType === 'boolean' || source === null || source === undefined) {
    return source
  } else if (source instanceof Array) {
    return source.map(cloneDeep)
  } else if (source instanceof Date) {
    return new Date(source.getTime())
  } else if (source instanceof Blob) {
    return source // peut-être retourner un slice ?
  } else if (objectType === 'object') {
    return Object.keys(source)
      .reduce(function (clone, key) {
        clone[key] = cloneDeep(source[key])
        return clone
      }, {})
  } else {
    throw new Error(`unknown object type: ${objectType}`)
  }
}

/*
  Variante de clone deep qui préserve la classe de l'objet (utilise son constructeur pour recréer l'objet)
*/
export function cloneDeepClass (source) {
  if (source instanceof Array) {
    return source.map(cloneDeepClass)
  }
  return new source.constructor(source)
}

/*
  Retourne la différence entre les 2 tableaux. (array - otherArray)
*/
export function difference (array, otherArray) {
  if (!Array.isArray(array)) {
    throw new TypeError(`Expected an array, got '${typeof array}'`)
  }
  if (!Array.isArray(otherArray)) {
    throw new TypeError(`Expected an array, got '${typeof otherArray}'`)
  }
  return array.filter(e => otherArray.indexOf(e) === -1)
}

// Retourne la différence entre 2 tableaux avec un critère de comparaison (algo naif O(n²))
export function differenceBy (array, otherArray, compareAttribute) {
  if (!Array.isArray(array)) {
    throw new TypeError(`Expected an array, got '${typeof array}'`)
  }
  if (!Array.isArray(otherArray)) {
    throw new TypeError(`Expected an array, got '${typeof otherArray}'`)
  }
  if (!compareAttribute) {
    throw new Error('Missing param compareAttribute')
  }
  return array.filter(e => otherArray.every(o => e[compareAttribute] !== o[compareAttribute]))
}

// retourne l'objet avec les mêmes clés mais ses valeurs transformées
export function mapValues (object, func) {
  return Object.keys(object)
    .reduce(function (acc, key) {
      acc[key] = func(object[key])
      return acc
    }, {})
}

export function generateDatesForDateRange (from, to) {
  return generateDatesForDateStringRange(getISODate(from), getISODate(to))
}

export function generateDatesForDateStringRange (from, to) {
  const listDaysString = []
  let currentDate = from

  // compteur pour éviter de faire de boucle infinie au cas où
  let counter = 0
  const maxRangeSize = 1000
  while (currentDate !== to) {
    listDaysString.push(currentDate)

    const currentDay = parseInt(getDay(currentDate), 10)
    const currentMonthStr = getMonth(currentDate)
    const currentMonth = parseInt(currentMonthStr, 10)
    const currentYear = parseInt(getYear(currentDate), 10)
    if (currentMonth === 12 && currentDay === 31) {
      const nextYear = currentYear + 1
      currentDate = `${nextYear}-01-01`
    } else if (currentDay === getNumberOfDaysInMonth(currentYear, currentMonth)) {
      const nextMonth = currentMonth >= 9 ? currentMonth + 1 : '0' + (currentMonth + 1)
      currentDate = `${currentYear}-${nextMonth}-01`
    } else {
      const nextDay = currentDay >= 9 ? currentDay + 1 : '0' + (currentDay + 1)
      currentDate = `${currentYear}-${currentMonthStr}-${nextDay}`
    }
    if (++counter === maxRangeSize) {
      throw new Error(`Planning: Max range size reached (${maxRangeSize}). Failed to generate days range from ${from} to ${to}. Current day was ${currentDate}.`)
    }
  }
  listDaysString.push(currentDate)

  return listDaysString
}

// manipule des mois de 1 à 12
export function getNumberOfDaysInMonth (year, month) {
  const daysInMonths = [-1, 31, 0, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
  return month === 2 ? getFebruaryDays(year) : daysInMonths[month]
}

export function getFebruaryDays (year) {
  return ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) ? 29 : 28
}

export function getYear (date) {
  return date.substr(0, 4)
}
export function getMonth (date) {
  return date.substr(5, 2)
}
export function getDay (date) {
  return date.substr(8, 2)
}

export function downloadFile (url, nom) {
  const link = document.createElement('a')
  document.body.appendChild(link)
  link.type = 'hidden'
  link.href = url
  link.download = nom
  link._target = '_blank'
  link.click()
  document.body.removeChild(link)
  URL.revokeObjectURL(url)
}

export async function resizeImage (imageCanvas, maxWidth) {
  const aspectRatio = imageCanvas.width / imageCanvas.height
  const canvas = document.createElement('canvas')
  canvas.width = imageCanvas.width > maxWidth ? maxWidth : imageCanvas.width
  canvas.height = imageCanvas.width > maxWidth ? maxWidth / aspectRatio : imageCanvas.height
  const ctx = canvas.getContext('2d')
  ctx.drawImage(imageCanvas, 0, 0, canvas.width, canvas.height)
  return new Promise(resolve => {
    canvas.toBlob(resolve, 'image/jpeg', 0.95)
  })
}

export function skipFirst (func) {
  let firstSkipped = false
  function wrapper (...args) {
    if (firstSkipped) {
      return func.apply(this, args)
    }
    firstSkipped = true
  }
  return wrapper
}
