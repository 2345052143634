import { Entity } from './Entity'

export const RecipeTag = class RecipeTag extends Entity {
  /*
  properties:
    name: string
  */
  static fields () {
    return {
      name: ''
    }
  }

  get kind () {
    return 'RecipeTag'
  }

  destroy (state) {
    return state.recipes
      .filter(recipe => recipe.containsTag(this._id))
      .map(recipe => recipe.removeTag(this._id))
  }
}
