<template lang="pug">
router-view(:recipe="recipe" :key="$route.fullPath" v-if="recipe")
</template>

<script>
export default {
  props: {
    recipeSlug: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      loading: false,
      recipe: null
    }
  },
  async created () {
    this.recipe = this.$store.state.library.recipes.find(recipe => recipe.slug === this.recipeSlug)
    if (!this.recipe) {
      this.$ui.error(`Unknown recipe '${this.recipeSlug}'`)
      await this.$router.push('/recipes')
    }
  }
}
</script>
