import getters from './getters'
import mutations from './mutations'

export function buildLibraryStore (initialState) {
  return {
    namespaced: true,
    state: createInitialState(initialState),
    getters,
    mutations
  }
}

/*
Create a state object from a dump document listing.
*/
function createInitialState (initialState) {
  return {
    ingredientCategories: initialState.ingredientCategories,
    ingredients: initialState.ingredients,
    recipes: initialState.recipes,
    recipeTags: initialState.recipeTags,
    planningDays: initialState.planningDays,
    thingsToBuy: initialState.thingsToBuy,

    recipesLibrary: {
      recipes: [],
      filters: {
        recipeName: '',
        servings: [1, 20],
        cookingTimes: [],
        complexities: [],
        lastUse: '',
        recipeTags: [],
        ingredients: []
      }
    },
    ingredientsLibrary: {
      ingredients: [],
      filters: {
        ingredientName: '',
        ingredientCategories: []
      }
    }
  }
}
