import fuzzy from 'fuzzy'
import Vue from 'vue'

import { createEntity, kindToCollection } from '@/api/helper'
import { Entity } from '@/api/models/Entity'
import * as _ from '@/util'

const cookingTimeFilter = {
  '-10': time => time >= 0 && time <= 10,
  '10-30': time => time >= 10 && time <= 30,
  '30-1h': time => time >= 30 && time <= 60,
  '+1h': time => time >= 60 && time <= 999
}
const fuzzyOptions = {
  pre: '<span>',
  post: '</span>'
}

export default {
  addDocument (state, rawDocument) {
    const document = createEntity(rawDocument.kind, rawDocument)
    state[kindToCollection(document.kind)].push(document)
  },
  updateDocument (state, rawDocument) {
    const document = createEntity(rawDocument.kind, rawDocument)
    const existingDocument = state[kindToCollection(rawDocument.kind)].find(doc => doc._id === document._id)
    if (!existingDocument) {
      throw new Error(`Could not find document ${document.slug}`)
    }
    Object.assign(existingDocument, document)
  },
  deleteDocument (state, document) {
    if (!(document instanceof Entity)) {
      throw new TypeError(`Expected Entity, but got '${typeof document}'`)
    }
    state[kindToCollection(document.kind)] = state[kindToCollection(document.kind)].filter(d => d._id !== document._id)
  },
  updateThingsToBuy (state, thingsToBuy) {
    state.thingsToBuy = thingsToBuy
  },
  updateLibraryFilters (state, libraryFilters) {
    // mise à jour des filtres
    state.recipesLibrary.filters = _.cloneDeep(libraryFilters)

    const recipeNameFilter = _.normalize(libraryFilters.recipeName).trim()

    state.recipesLibrary.recipes = state.recipes
      // recipe name contains fuzzy search filter
      .filter(recipe => {
        Vue.set(recipe, 'filteredName', recipe.name)
        if (recipeNameFilter === '') {
          return true
        }

        const results = fuzzy.filter(recipeNameFilter, [_.normalize(recipe.name)], fuzzyOptions)
        const match = (results.length > 0)
        if (match) {
          recipe.filteredName = _.capitalize(results[0].string)
        }
        return match
      })

      // servings > filter
      .filter(recipe => libraryFilters.servings === 0 || (libraryFilters.servings[0] <= recipe.servings && recipe.servings <= libraryFilters.servings[1]))

      // filterMin <= cookingTime <= filterMax
      .filter(recipe => libraryFilters.cookingTimes.length === 0 || libraryFilters.cookingTimes.some(filter => cookingTimeFilter[filter](recipe.cookingTime)))

      // lastUse
      .filter(recipe => {
        return true
        // switch (libraryFilters.lastUse) {
        //   case "last_week":
        //   case "last_month":
        //   case "last_3_months":
        //   case "before_last_3_months":
        //   // TODO filter days
        // }
      })

      // complexity contains filter
      .filter(recipe => libraryFilters.complexities.length === 0 || libraryFilters.complexities.includes(recipe.complexity))

      // tags contains filter
      .filter(recipe => _.difference(libraryFilters.recipeTags, recipe.tagsIds).length === 0)

      // recipe ingredients contains filter
      .filter(recipe => {
        return libraryFilters.ingredients.length === 0 || libraryFilters.ingredients.every(ingredientId => {
          return recipe.ingredientUsages.some(usage => usage.ingredientId === ingredientId)
        })
      })

      .sort((a, b) => _.localeStringComparator(a.name, b.name))
  },
  updateDefaultServings (state, defaultServings) {
    state.planning.defaultServings = defaultServings
  },
  updateIngredientsLibraryFilters (state, libraryFilters) {
    // mise à jour des filtres
    state.ingredientsLibrary.filters = _.cloneDeep(libraryFilters)

    const ingredientNameFilter = _.normalize(libraryFilters.ingredientName).trim()

    state.ingredientsLibrary.ingredients = state.ingredients
      // ingredient name contains fuzzy search filter
      .filter(ingredient => {
        Vue.set(ingredient, 'filteredName', ingredient.name)
        if (ingredientNameFilter === '') {
          return true
        }

        const results = fuzzy.filter(ingredientNameFilter, [_.normalize(ingredient.name)], fuzzyOptions)
        const match = (results.length > 0)
        if (match) {
          ingredient.filteredName = _.capitalize(results[0].string)
        }
        return match
      })

      // filter contains ingredient category
      .filter(ingredient => libraryFilters.ingredientCategories.length === 0 || libraryFilters.ingredientCategories.includes(ingredient.categoryId))

      // sort by category name then by ingredient name
      .sort((a, b) => _.localeStringComparator(a.category ? a.category.name : '', b.category ? b.category.name : '') || _.localeStringComparator(a.name, b.name))
  },

  // misc
  loadLibrary (state, libraryDump) {
    Object.assign(state, libraryDump)
  }
}
