<template lang="pug">
.mp-page
  h4.mp-section-title Ingredient Categories

  p.
    Categories are used to group the ingredients in the shopping list.
    Every ingredient must be attached to a category.

  .ingredient-categories
    draggable(:list="reorderedIngredientCategories" handle=".category__handle" @start="onDragStart" @end="onDragEnd")
      .category(v-for="category in ingredientCategories" :key="category._id")
        v-text-field.category__name(v-if="category._id === editingCategory._id" v-model="editingCategory.name" :rules="editingCategoryNameRules" @keyup.enter="updateCategory()")
        .category__name(v-else) {{ category.name }}

        .category__actions(v-if="category._id === editingCategory._id")
          v-btn(icon title="Update category" @click="updateCategory()")
            v-icon mdi-check
          v-btn(icon title="Cancel" @click="cancelCategoryEditing()")
            v-icon mdi-close
        .category__actions(v-else)
          v-btn(icon class="category__handle")
            v-icon mdi-swap-vertical
          v-btn(icon title="Rename category" @click="startCategoryEditing(category)")
            v-icon mdi-pencil
          v-btn(icon title="Delete category" @click="deleteCategory(category)")
            v-icon mdi-delete

    v-form.mp-row(v-model="validNewCategoryForm" ref="newCategoryForm" @submit.prevent="")
      v-text-field(v-model="newCategory.name" placeholder="New category" :rules="newCategoryNameRules" @keyup.enter="addCategory")
      v-btn(icon title="Create a new category" @click="addCategory" :disabled="!validNewCategoryForm")
        v-icon mdi-plus

  mp-edit-recipe-tags
</template>

<script>
import draggable from 'vuedraggable'

import { IngredientCategory } from '@/api/models/IngredientCategory'
import MpEditRecipeTags from '@/components/MpEditRecipeTags'
import * as _ from '@/util'

export default {
  name: 'IngredientCategories',
  components: {
    MpEditRecipeTags,
    draggable
  },
  data () {
    return {
      newCategory: null,
      validNewCategoryForm: true,
      editingCategory: {},
      editingCategoryNameRules: [
        (v) => !!v || 'Name is required',
        (v) => !this.otherCategorySlugs.includes(this.newCategory.slugged) || 'This category already exists'
      ],
      otherCategorySlugs: [],
      newCategoryNameRules: [
        (v) => !!v || 'Name is required',
        (v) => !this.categorySlugs.includes(this.newCategory.slugged) || 'This category already exists'
      ],
      reorderingInProgress: false,
      reorderedIngredientCategories: []
    }
  },
  computed: {
    ingredientCategories () {
      return this.reorderingInProgress ? this.reorderedIngredientCategories : this.$store.getters['library/ingredientCategories']
    },
    categorySlugs () {
      return this.ingredientCategories.map(c => c.slug)
    },
    ingredients () {
      return this.$store.state.library.ingredients
    }
  },
  created () {
    this.cleanNewCategory()
  },
  methods: {
    onDragStart () {
      this.reorderedIngredientCategories = _.cloneDeepClass(this.$store.getters['library/ingredientCategories'])
      this.reorderingInProgress = true
    },
    async onDragEnd () {
      await Promise.all(this.reorderedIngredientCategories.map(async (category, index) => {
        category.order = index
        await this.$api.updateDocument(category)
      }))
      this.reorderingInProgress = false
    },
    startCategoryEditing (category) {
      this.editingCategory = category.clone()
      const normalizedName = category.slugged
      this.otherCategorySlugs = this.categorySlugs.filter(n => n !== normalizedName)
    },
    cancelCategoryEditing () {
      this.editingCategory = {}
    },
    cleanNewCategory () {
      this.newCategory = new IngredientCategory()
    },
    async addCategory () {
      if (this.$refs.newCategoryForm.validate()) {
        const categories = this.ingredientCategories
        this.newCategory.order = categories.length > 0 ? categories[categories.length - 1].order + 1 : 0
        await this.$api.addDocument(this.newCategory)
        this.$refs.newCategoryForm.resetValidation()
        this.cleanNewCategory()
      }
    },
    async updateCategory () {
      await this.$api.updateDocument(this.editingCategory)
      this.editingCategory = {}
    },
    async deleteCategory (category) {
      const ingredientWithSelectedCategory = this.$store.state.library.ingredients
        .filter(ingredient => ingredient.categoryId === category._id)
        .map(i => i.name)
      let message = ingredientWithSelectedCategory.join('\n- ')
      if (message) {
        message = `\n- ${message}`
      }
      message = `Are you sure?\nDeleting the category will remove it from the ingredients using it. There are currently ${ingredientWithSelectedCategory.length} ingredients(s) using this category.${message}`
      this.$ui.confirm(message, async () => {
        await this.$api.deleteDocument(category)
      })
    }
  }
}
</script>

<style lang="stylus">
.ingredient-categories
  max-width 340px

.category
  display flex
  align-items center

  &__name
    flex 1

  &__handle
    cursor grab
</style>
