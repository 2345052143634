import Vue from 'vue'
import GlobalEvents from 'vue-global-events'
import Vuelidate from 'vuelidate'

import API from '@/api/api'
import App from '@/App'
import events from '@/events'
import logger from '@/logger'
import APIPlugin from '@/plugins/api'
import UiPlugin from '@/plugins/ui'
import vuetify from '@/plugins/vuetify'
import { createRouter } from '@/router'
import '@/filters/RecipeComplexity'
import '@/directives/AutoSize'
import './registerServiceWorker'

import '@/styles/main.styl'

Vue.config.devtools = process.env.NODE_ENV !== 'production'
Vue.config.performance = process.env.NODE_ENV !== 'production'
Vue.config.productionTip = false
Vue.use(Vuelidate)
Vue.use(UiPlugin)
Vue.component('GlobalEvents', GlobalEvents)

class MealPlannerApp {
  constructor (options) {
    if (!options.api) {
      throw new Error('MealPlannerApp: missing api')
    }
    this.api = options.api
  }

  async init () {
    logger.info('Initializing Application...')

    Vue.use(APIPlugin, {
      api: this.api
    })
    this.setupErrorHandler()

    new Vue({
      store: await this.api.buildVuexStore(),
      router: createRouter(this.api),
      vuetify,
      render: h => h(App)
    }).$mount('#app')
  }

  setupErrorHandler () {
    Vue.config.errorHandler = err => {
      this.errorHandler(err)
    }
    window.addEventListener('error', event => {
      event.preventDefault()
      this.errorHandler(event.error || event)
    })
    window.addEventListener('unhandledrejection', event => {
      event.preventDefault()
      this.errorHandler(event.reason)
    })
  }

  errorHandler (error) {
    if (!error) {
      logger.error('Received an empty error ¯＼(º_o)/¯')
      return
    }
    logger.error(error.stack)
    events.bus.$emit(events.Alert, 'error', error.message)
  }
}

(async function () {
  try {
    await new MealPlannerApp({
      api: new API()
    }).init()
    logger.info('Application started')
    document.getElementById('app-error').remove()
  } catch (err) {
    logger.error('Failed to start Meal Planner App', err)
    document.getElementById('app-error').style.display = 'flex'
    document.getElementById('app-loader').remove()
  }
})()
