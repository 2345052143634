import { Ingredient } from './models/Ingredient'
import { IngredientCategory } from './models/IngredientCategory'
import { PlanningDay } from './models/PlanningDay'
import { Recipe } from './models/Recipe'
import { RecipeTag } from './models/RecipeTag'
import { ThingsToBuy } from './models/ThingsToBuy'

export function createEntity (kind, data) {
  switch (kind) {
    case 'IngredientCategory': return new IngredientCategory(data)
    case 'Ingredient': return new Ingredient(data)
    case 'RecipeTag': return new RecipeTag(data)
    case 'Recipe': return new Recipe(data)
    case 'PlanningDay': return new PlanningDay(data)
    case 'ThingsToBuy': return new ThingsToBuy(data)
    default:
      throw new Error(`Unknown model '${kind}'`)
  }
}

export function kindToCollection (kind) {
  switch (kind) {
    case 'IngredientCategory': return 'ingredientCategories'
    case 'Ingredient': return 'ingredients'
    case 'RecipeTag': return 'recipeTags'
    case 'Recipe': return 'recipes'
    case 'PlanningDay': return 'planningDays'
    default:
      throw new Error(`Unknown model '${kind}'`)
  }
}
