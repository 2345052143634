import * as _ from '@/util'

// comparator that handles null values carefully at the end
function recentLastUsageSort (a, b) {
  if (a.lastUsage === b.lastUsage) {
    return 0
  }
  return (a.lastUsage || '0') < (b.lastUsage || '0') ? 1 : -1
}

export default {
  recentRecipes (state) {
    return state.recipes
      .slice()
      .sort(recentLastUsageSort)
  },
  recipes (state) {
    return Object.assign([], state.recipes)
      .slice()
      .sort((a, b) => _.localeStringComparator(a.name, b.name))
  },
  ingredients (state) {
    return state.ingredients
      .slice()
      .sort((a, b) => _.localeStringComparator(a.name, b.name))
  },
  ingredientCategories (state) {
    return state.ingredientCategories
      .slice()
      .sort((a, b) => a.order - b.order)
  },
  ingredientsWithCategories (state) {
    return state.ingredientsLibrary.ingredients
      .map(ingredient => {
        return {
          ...ingredient,
          category: state.ingredientCategories.find(category => category._id === ingredient.categoryId)
        }
      })
      .sort((a, b) => _.localeStringComparator(a.name, b.name))
  }
}
