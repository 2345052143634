<script>
import { cacheGet } from '@/api/attachment-url-cache'

const defaultRecipePhoto = '/img/empty_plate.png'

export default {
  props: {
    recipe: {
      type: Object,
      required: true
    },
    to: {
      type: String,
      default: undefined
    }
  },
  computed: {
    photoURI () {
      return this.recipe._attachments.photo ? cacheGet(this.recipe._attachments.photo) : defaultRecipePhoto
    }
  },
  render (h) {
    const hasLink = !!this.to
    const rootTag = this.to ? 'router-link' : 'div'

    const cardStyle = {}
    if (hasLink) {
      cardStyle.cursor = 'pointer'
    }
    return h(rootTag, {
      class: 'recipe-card',
      style: cardStyle,
      props: {
        to: this.to
      }
    }, [
      h('div', {
        class: 'recipe-card__photo',
        style: {
          backgroundImage: `linear-gradient(0deg, rgba(0,0,0,.7) 0%, transparent 33%), url(${this.photoURI})`
        }
      }),
      h('h3', {
        class: 'recipe-card__title',
        domProps: {
          innerHTML: this.recipe.filteredName
        }
      })
    ])
  }
}
</script>

<style lang="stylus" scoped>
.recipe-card
  display inline-block
  position relative
  overflow hidden

  &__photo
    position absolute
    width 100%
    height 100%
    background-position 50%
    background-size cover
    transition .6s ease-out

    &:hover
      transform scale(1.1)

  &__title
    display block
    position absolute
    bottom 5%
    left 5%
    line-height 1
    color #ffffff
    text-shadow 1px 1px #000
    font-weight 600
    word-break break-word

    >>> span
      color #78de8e

</style>
