export default class DateRange {
  constructor (start, end) {
    this.start = start
    this.end = end
  }

  contains (date) {
    return this.start <= date && date <= this.end
  }
}
