<template lang="pug">
.planning
  .planning__actions.ma-3
    v-btn(text small to="/planning" exact title="Current month") Today
    v-btn(icon :to="previousMonthLink" title="Previous month")
      v-icon mdi-chevron-left
    v-btn(icon :to="nextMonthLink" title="Next month")
      v-icon mdi-chevron-right

    span.planning__month {{ displayedDateTitle }}

    v-spacer

    v-btn.planning__shoppinglist-btn(v-if="$api.lastShoppingList" title="Open last shopping list" @click="openLastShoppingList()" )
      v-icon(left) mdi-folder-outline
      | Last shopping list

    v-btn.planning__shoppinglist-btn.ml-2(:class="{'btn--active': selectRangeMode}" :color="selectRangeMode ? 'teal' : 'default'"
                                          :title="selectRangeMode ? 'Select the range' : 'Shopping list'"
                                          @click="toggleSelectRangeMode()")
      v-icon(left) {{ selectRangeMode ? 'mdi-calendar-range' : 'mdi-printer' }}
      | {{ selectRangeMode ? 'Select the range' : 'Shopping list' }}

  .planning__content
    .planning__header-cell Monday
    .planning__header-cell Tuesday
    .planning__header-cell Wednesday
    .planning__header-cell Thursday
    .planning__header-cell Friday
    .planning__header-cell Saturday
    .planning__header-cell Sunday

    .planning-cell(v-for="planningDay in planningRange" :key="planningDay.date"
                    :class="getClassesForDay(planningDay)"
                  )
      .planning-cell__date(:class="{'planning-cell__date--today': dateIsToday(planningDay.date)}")
        | {{ planningDay.day }}

      .planning-cell__recipes
        mp-recipe-usage(v-for="(recipeUsage, recipeUsageIndex) in planningDay.recipeUsages" :key="recipeUsageIndex"
                        :recipeUsage="recipeUsage")

      .planning-cell__link(v-if="selectRangeMode"
                            @mousedown="handleMouseDown(planningDay)"
                            @mouseover="handleMouseOver(planningDay)"
                          )
      router-link.planning-cell__link(v-if="!selectRangeMode" :to="`/planning/${planningDay.date}`")

  router-view
</template>

<script>
import DateRange from '@/api/models/DateRange'
import { Meals } from '@/api/models/RecipeUsage'
import MpRecipeUsage from '@/components/MpRecipeUsage'
import * as _ from '@/util'

export default {
  components: {
    MpRecipeUsage
  },
  props: {
    year: {
      type: String,
      required: true
    },
    month: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      selectRangeMode: false,
      shoppingListDialog: false,
      currentDate: new Date(),

      // shopping list
      shoppingListRange: null,
      shoppingListStartDate: null,
      shoppingList: null
    }
  },
  computed: {
    yearMonth () {
      return `${this.year}-${this.month}`
    },
    previousYearMonth () {
      return `${this.previousMonthInfos.year}-${this.previousMonthInfos.monthZeroPadded}`
    },
    nextYearMonth () {
      return `${this.nextMonthInfos.year}-${this.nextMonthInfos.monthZeroPadded}`
    },
    planningRange () {
      return this.dateRange.map(day => {
        const existingPlanningDay = this.planningDays[day.date]
        day.recipeUsages = existingPlanningDay
          ? existingPlanningDay.listRecipeUsages(this.$store.state)
            .sort((a, b) => {
              return Meals[a.meal].order < Meals[b.meal].order ? -1 : 1
            })
          : []
        return day
      })
    },
    planningDays () {
      const months = [this.previousYearMonth, this.yearMonth, this.nextYearMonth]
      return this.$store.state.library.planningDays
        .filter(planningDay => months.includes(planningDay.date.substr(0, 7)))
        .reduce((acc, planningDay) => {
          acc[planningDay.date] = planningDay
          return acc
        }, {})
    },
    previousMonthInfos () {
      return this.getYearMonth(parseInt(this.year, 10), parseInt(this.month, 10) - 1, 1)
    },
    currentMonthInfos () {
      return this.getYearMonth(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1)
    },
    displayedMonthInfos () {
      return this.getYearMonth(parseInt(this.year, 10), parseInt(this.month, 10), 1)
    },
    nextMonthInfos () {
      return this.getYearMonth(parseInt(this.year, 10), parseInt(this.month, 10) + 1, 1)
    },
    previousMonthLink () {
      return `/planning/${this.previousYearMonth}`
    },
    currentMonthLink () {
      return `/planning/${this.currentMonthInfos.year}-${this.currentMonthInfos.monthZeroPadded}`
    },
    nextMonthLink () {
      return `/planning/${this.nextYearMonth}`
    },
    displayedDate () {
      return new Date(this.year, this.month - 1, 1)
    },
    displayedDateTitle () {
      return _.capitalize(this.displayedDate.toLocaleString('en-en', {
        month: 'long',
        year: 'numeric'
      }))
    },
    dateRange () {
      return Array.prototype.concat.call([],
        this.completeFirstWeekWithPreviousMonthDays(),
        this.completeMiddleWeeks(),
        this.completeLastWeekWithNextMonthDays()
      )
    }
  },
  methods: {

    // events
    toggleSelectRangeMode () {
      this.selectRangeMode = !this.selectRangeMode
      this.shoppingListRange = null
    },
    // catch 2 types of events: start and end
    async handleMouseDown (day) {
      if (!this.shoppingListRange) {
        this.shoppingListRange = new DateRange(day.date, day.date)
        this.shoppingListStartDate = day.date
      } else {
        await this.$router.push(`/planning/shoppinglist/${this.shoppingListRange.start}/${this.shoppingListRange.end}`)
        this.selectRangeMode = false
        this.shoppingListRange = null
      }
    },
    handleMouseOver (day) {
      if (this.shoppingListRange) {
        if (day.date < this.shoppingListStartDate) {
          this.shoppingListRange.start = day.date
          this.shoppingListRange.end = this.shoppingListStartDate
        } else {
          this.shoppingListRange.start = this.shoppingListStartDate
          this.shoppingListRange.end = day.date
        }
      }
    },
    getClassesForDay (day) {
      const classes = []
      if (this.selectRangeMode) {
        classes.push('planning-cell--selectRangeMode')
      }
      if (this.shoppingListRange && this.shoppingListRange.contains(day.date)) {
        classes.push('planning-cell--selected')
      }
      return classes
    },
    dateIsToday (date) {
      return date === _.getISODate(this.currentDate)
    },
    getISODate: _.getISODate,
    completeFirstWeekWithPreviousMonthDays () {
      const weekDay = this.getWeekDay(this.displayedMonthInfos.year, this.displayedMonthInfos.month, 1)
      return this.completeNDaysFrom(weekDay, this.previousMonthInfos.year, this.previousMonthInfos.month, this.previousMonthInfos.daysInMonth + 1 - weekDay)
    },
    completeMiddleWeeks () {
      return this.completeNDaysFrom(this.displayedMonthInfos.daysInMonth, this.displayedMonthInfos.year, this.displayedMonthInfos.month, 1)
    },
    completeLastWeekWithNextMonthDays () {
      const weekDay = this.getWeekDay(this.displayedMonthInfos.year, this.displayedMonthInfos.month, this.displayedMonthInfos.daysInMonth)
      return this.completeNDaysFrom(6 - weekDay, this.nextMonthInfos.year, this.nextMonthInfos.month, 1)
    },

    // manipule des mois de 1 à 12
    getYearMonth (year, month) {
      if (month < 1) {
        year--
        month = 12
      } else if (month > 12) {
        year++
        month = 1
      }
      return {
        year: year,
        month: month,
        monthZeroPadded: month >= 10 ? month : '0' + month,
        daysInMonth: _.getNumberOfDaysInMonth(year, month),
        date: new Date(Date.UTC(year, month - 1, 1))
      }
    },
    // get weekday starting at monday: 0 monday to 6 sunday
    // original Date.getDay: 0 sunday - 6 saturday
    getWeekDay (year, month, day) {
      return (new Date(year, month - 1, day).getDay() + 6) % 7
    },
    // complète n jours à partir d'une date
    // ATTENTION, il faut rester dans le même mois
    completeNDaysFrom (count, year, month, day) {
      const days = []
      for (let i = 0; i < count; i++) {
        const date = new Date(Date.UTC(year, month - 1, day + i))
        days.push({
          date: date.toISOString().substr(0, 10),
          day: date.getDate()
        })
      }
      return days
    },

    getYear (date) {
      return date.substr(0, 4)
    },
    getMonth (date) {
      return date.substr(5, 2)
    },
    getDay (date) {
      return date.substr(8, 2)
    },
    async openLastShoppingList () {
      await this.$router.push('/planning/shoppinglist/saved')
    }
  }
}
</script>

<style lang="stylus">
$cellBgColor = rgb(251, 236, 255)
$cellSelectRangeModeBgColor = #deddf1
$cellSelectedBgColor = darken($cellSelectRangeModeBgColor, 5)
$cellTodayBgColor = #9cd3ff

$cell-border = 1px solid rgb(251, 236, 255)

.planning
  height 100%
  display flex
  flex-direction column

  @media (max-width 1024px)
    font-size .8em

  @media (max-width 540px)
    font-size .6em

  &__actions
    height 40px
    margin-bottom 20px
    display flex
    align-items center

  &__month
    font-size 1.6em
    letter-spacing 0.0125em
    margin-left 16px

    @media (max-width 450px)
      font-size 1.5em

  &__shoppinglist-btn

    @media (max-width 740px)
      font-size 0 !important

    @media (max-width 740px)
      .v-icon--left
        margin 0 !important

  &__content
    flex 1
    display flex
    flex-direction column
    overflow-y auto
    display grid
    grid-template-columns repeat(7, calc(100%/7))
    grid-template-rows min-content auto

  &__header-cell
    position sticky
    top 0
    z-index 1
    padding .2em
    font-weight bold
    font-size 1.2em
    text-align center
    background-color #fafafa
    border $cell-border

.planning-cell
  position relative
  flex-basis (100/7)%
  border $cell-border
  background-color #ffffff
  text-align center

  &:hover
    background-color $cellBgColor

  &--selectRangeMode
    background-color $cellSelectRangeModeBgColor

    &:hover
      background-color $cellSelectedBgColor

  &--selected
    background-color $cellSelectedBgColor

  &__date

    &--today
      display: inline-block
      background-color $cellTodayBgColor
      border-radius 50%
      min-width: 24px
      line-height: 24px

  // a link covers all the cell
  &__link
    position absolute
    top 0
    left 0
    right 0
    bottom 0
    cursor pointer
</style>
