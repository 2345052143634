import { Entity } from './Entity'

export const Ingredient = class Ingredient extends Entity {
  /*
  properties:
    name: string
    hideInShoppingList: boolean

  relations:
    category: IngredientCategory
  */
  static fields () {
    return {
      name: '',
      hideInShoppingList: false,
      categoryId: null
    }
  }

  get kind () {
    return 'Ingredient'
  }

  destroy (state) {
    return state.recipes
      .filter(recipe => recipe.containsIngredient(this._id))
      .map(recipe => recipe.clone().removeIngredient(this._id))
  }

  resetCategory () {
    this.categoryId = null
    return this
  }
}
