<template lang="pug">
v-dialog(value="true" fullscreen transition="dialog-bottom-transition")
  global-events(@keydown.esc="goToPlanning()")
  v-card
    v-toolbar
      v-btn(icon @click="goToPlanning()")
        v-icon mdi-close
      v-toolbar-title
        span.hidden-xs-only Shopping List :&nbsp;
        | {{ shoppingList.from }}&nbsp;
        v-icon mdi-arrow-right
        |  {{ shoppingList.to }}
    mp-shopping-list(:shoppingList="shoppingList")
</template>

<script>
import { ShoppingList } from '@/api/models/ShoppingList'
import MpShoppingList from '@/components/MpShoppingList'

export default {
  components: {
    MpShoppingList
  },
  props: {
    start: {
      type: String,
      default: ''
    },
    end: {
      type: String,
      default: ''
    },
    loadSaved: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      shoppingList: null
    }
  },
  watch: {
    shoppingList: {
      handler (val, oldVal) {
        if (val) {
          this.$api.saveLastShoppingList(val)
        }
      },
      deep: true
    }
  },
  created () {
    if (this.loadSaved) {
      this.shoppingList = this.$api.loadLastShoppingList()
      return
    }
    if (!this.start || !this.end) {
      throw new Error('Missing start or end')
    }
    const shoppingListPlanningDays = this.$store.state.library.planningDays.filter(planningDay => {
      return this.start <= planningDay.date && planningDay.date <= this.end
    })
    this.shoppingList = new ShoppingList({
      from: this.start,
      to: this.end,
      planningDays: shoppingListPlanningDays
    })
    this.shoppingList.generate(this.$store.state.library)
  },
  methods: {
    goToPlanning () {
      this.$router.push('/planning')
    }
  }
}
</script>
