import { v4 as uuidv4 } from 'uuid'

import * as _ from '@/util'

export const Entity = class Entity {
  static commonFields () {
    return [
      '_id',
      '_rev',
      'slug',
      'createdAt',
      'updatedAt'
    ]
  }

  constructor (options = {}) {
    // assigns only properties that are defined in the model
    this.constructor.commonFields().forEach(prop => {
      if (typeof options[prop] !== 'undefined') {
        this[prop] = _.cloneDeep(options[prop])
      }
    })
    if (typeof this.constructor.fields !== 'function') {
      throw new Error(`Missing "fields" function for class ${this.constructor.name}`)
    }
    const fields = this.constructor.fields()
    Object.keys(fields).forEach(prop => {
      this[prop] = typeof options[prop] !== 'undefined' ? _.cloneDeep(options[prop]) : fields[prop] // default value
    })
  }

  get slugged () {
    return _.slugify(this.name)
  }

  /*
    Returns a pouchdb-compatible object
  */
  toPouchDBFormat () {
    const returnObj = {}
    Object.keys(this.constructor.fields()).forEach(prop => {
      returnObj[prop] = this[prop]
    })
    Entity.commonFields().forEach(prop => {
      returnObj[prop] = this[prop]
    })
    returnObj.kind = this.kind
    returnObj.slug = this.slugged
    returnObj.updatedAt = new Date()
    if (!this._id) {
      returnObj._id = uuidv4()
      returnObj.createdAt = returnObj.updatedAt
    }
    return returnObj
  }

  clone () {
    return new this.constructor(this)
  }

  destroy () {
    return []
  }
}
