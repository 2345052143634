<template lang="pug">
v-app
  v-main.login__panelwrapper.pa-4
    v-container.login__panel(elevation-24)
      h1.login__title
        v-icon(x-large color="teal") mdi-silverware-fork-knife
        br
        | Meal Planner
      v-form(v-model="valid" method="post" @submit.prevent)
        v-text-field(label="Email" type="email" v-model="email" :rules="emailRules" required autofocus)
        v-text-field(type="password" label="Password" v-model="password" :rules="passwordRules" required)
        mp-btn(type="submit" block color="primary" :action="login" :disableif="!valid") Login

    .login__createaccountlink
      | New to Meal Planner?&nbsp;
      router-link(to="/register") Create an account
</template>

<script>
import MpBtn from '@/components/MpBtn'

export default {
  name: 'Login',
  components: {
    MpBtn
  },
  data () {
    return {
      showError: false,
      errorMessage: '',
      valid: false,
      email: '',
      password: '',
      emailRules: [
        (v) => !!v || 'E-mail is required'
      ],
      passwordRules: [
        (v) => !!v || 'Password is required'
      ]
    }
  },
  created () {
    this.email = localStorage.getItem('mp-lastaccount') || ''
  },
  methods: {
    async login () {
      await this.$api.login({
        email: this.email,
        password: this.password
      })
      await this.$router.push(this.$route.query.redirect || '/')
    }
  }
}
</script>

<style lang="stylus">

.login

  &__panelwrapper
    align-items: center

  &__panel
    text-align: center
    max-width: 400px

  &__title
    font-size: 2em
    color: rgb(0, 130, 103)

  &__createaccountlink
    margin-top: 2em
    font-size: 0.9em
    text-align: center

#error-alert
  position: fixed
  top: 25px
  left: 25px
  right: 25px
  text-align: center
  z-index:9999

</style>
