import { Entity } from './Entity'

export const recipeComplexities = [
  {
    key: 'simple',
    label: 'Simple'
  },
  {
    key: 'medium',
    label: 'Medium'
  },
  {
    key: 'difficult',
    label: 'Difficult'
  }
]

export const Recipe = class Recipe extends Entity {
  /*
  properties:
    name: string
    servings: int
    cookingTime: int
    complexity: enum(string)
    directions: []string
    links: []string
    photoId: int
    lastUsage: string

  relations:
    ingredientUsages: []Ingredient
    tags: []RecipeTag
  */
  static fields () {
    return {
      name: '',
      servings: 1,
      cookingTime: 0,
      complexity: 'medium',
      directions: [],
      links: [],
      photoId: 0,
      lastUsage: null,
      ingredientUsages: [],
      tagsIds: [],
      _attachments: {}
    }
  }

  get kind () {
    return 'Recipe'
  }

  listIngredientUsages (state) {
    return this.ingredientUsages.map(usage => {
      const ingredient = state.library.ingredients.find(ingredient => ingredient._id === usage.ingredientId)
      if (!ingredient) {
        throw new Error(`Missing ingredient ${usage.ingredientId}. Corrupt database?`)
      }
      return {
        ...usage,
        ingredient
      }
    })
  }

  listTags (state) {
    return this.tagsIds.map(tagId => state.library.recipeTags.find(tag => tag._id === tagId))
  }

  destroy (state) {
    return state.planningDays
      .filter(planningDay => planningDay.containsRecipe(this._id))
      .map(planningDay => planningDay.clone().removeRecipe(this._id))
  }

  // relation Ingredient

  containsIngredient (ingredientId) {
    return this.ingredientUsages.some(usage => usage.ingredientId === ingredientId)
  }

  removeIngredient (ingredientId) {
    this.ingredientUsages = this.ingredientUsages.filter(usage => usage.ingredientId !== ingredientId)
    return this
  }

  addIngredientUsage (ingredientUsage) {
    if (this.ingredientUsages.map(usage => usage.ingredientId).indexOf(ingredientUsage.ingredientId) !== -1) {
      throw new Error('Recipe: ingredient is already present')
    }
    this.ingredientUsages.push(ingredientUsage)
  }

  removeIngredientUsage (ingredientUsageIndex) {
    this.ingredientUsages.splice(ingredientUsageIndex, 1)
  }

  // relation RecipeTag

  containsTag (tagId) {
    return this.tagsIds.includes(tagId)
  }

  addTag (tagId) {
    if (this.tagsIds.indexOf(tagId) !== -1) {
      throw new Error(`Recipe: tag ${tagId} is already present`)
    }
    this.tagsIds.push(tagId)
    return this
  }

  removeTag (tagId) {
    this.tagsIds = this.tagsIds.filter(id => id !== tagId)
    return this
  }
}
