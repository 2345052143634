import { Ingredient } from '../Ingredient'
import { IngredientCategory } from '../IngredientCategory'
import { PlanningDay } from '../PlanningDay'
import { Recipe } from '../Recipe'
import { RecipeTag } from '../RecipeTag'
import { RecipeUsage } from '../RecipeUsage'

export default {
  ingredientCategories: [
    new IngredientCategory({
      _id: 'boulangerie',
      name: 'Boulangerie'
    }),
    new IngredientCategory({
      _id: 'viandes',
      name: 'Viandes'
    }),
    new IngredientCategory({
      _id: 'produits-laitiers',
      name: 'Produits laitiers'
    }),
    new IngredientCategory({
      _id: 'fruits-et-legumes',
      name: 'Fruits et légumes'
    }),
    new IngredientCategory({
      _id: 'boissons',
      name: 'Boissons'
    }),
    new IngredientCategory({
      _id: 'epices',
      name: 'Épices'
    }),
    new IngredientCategory({
      _id: 'junk-food',
      name: 'Junk food'
    })
  ],
  ingredients: [
    new Ingredient({
      _id: 'farine',
      name: 'Farine',
      categoryId: 'boulangerie'
    }),
    new Ingredient({
      _id: 'levure',
      name: 'Levure',
      categoryId: 'boulangerie'
    }),
    new Ingredient({
      _id: 'jambon',
      name: 'Jambon',
      categoryId: 'viandes'
    }),
    new Ingredient({
      _id: 'beurre',
      name: 'Beurre',
      categoryId: 'produits-laitiers'
    }),
    new Ingredient({
      _id: 'oeuf',
      name: 'Oeuf',
      categoryId: 'produits-laitiers'
    }),
    new Ingredient({
      _id: 'biere',
      name: 'Bière',
      categoryId: 'boissons'
    }),
    new Ingredient({
      _id: 'eau',
      name: 'Eau',
      categoryId: 'boissons'
    }),
    new Ingredient({
      _id: 'sucre',
      name: 'Sucre',
      categoryId: 'boulangerie'
    }),
    new Ingredient({
      _id: 'huile',
      name: 'Huile',
      categoryId: 'epices'
    }),
    new Ingredient({
      _id: 'riz',
      name: 'Riz',
      categoryId: 'epices'
    }),
    new Ingredient({
      _id: 'sel',
      name: 'Sel',
      categoryId: 'epices'
    })
  ],
  recipes: [
    new Recipe({
      _id: 'pizza',
      name: 'Pizza',
      servings: 4,
      complexity: 'medium',
      cookingTime: 40,
      ingredientUsages: [
        {
          quantity: 400,
          type: 'g',
          ingredientId: 'farine'
        },
        {
          quantity: 4,
          type: 'u',
          ingredientId: 'jambon'
        },
        {
          quantity: 5,
          type: 'g',
          ingredientId: 'sel'
        },
        {
          quantity: 266,
          type: 'mL',
          ingredientId: 'eau'
        }
      ],
      directions: [
        'Mélanger la farine, la levure, le sel dans un saladier et laisser reposer la pâte pendant 30 minutes.',
        'Étaler la pâte puis y déposer la garniture.',
        'Enfourner à 180°C pendant 15-20 minutes.'
      ],
      links: [
        'http://www.marmiton.org/recettes/recettes-incontournables-detail_pizza_r_40.aspx',
        'https://www.youtube.com/watch?v=Snmlg5svP3U',
        'http://www.marmiton.org/recettes/recettes-incontournables-detail_pizza_r_40.aspx'
      ],
      tags: ['plat']
    }),
    new Recipe({
      _id: 'omelette',
      name: 'Omelette',
      image: 'assets/images/omelette.jpg',
      servings: 1,
      complexity: 'simple',
      cookingTime: 20,
      ingredientUsages: [
        {
          quantity: 4,
          type: 'u',
          ingredientId: 'oeuf'
        },
        {
          quantity: 150,
          type: 'g',
          ingredientId: 'riz'
        }
      ],
      tags: ['plat', 'vegetarien']
    }),
    new Recipe({
      _id: 'galettes',
      name: 'Galettes',
      image: 'assets/images/galettes.jpg',
      servings: 2,
      complexity: 'simple',
      cookingTime: 30,
      ingredientUsages: [
        {
          quantity: 100,
          type: 'g',
          ingredientId: 'farine'
        },
        {
          quantity: 200,
          type: 'mL',
          ingredientId: 'eau'
        },
        {
          quantity: 1,
          type: 'u',
          ingredientId: 'oeuf'
        }
      ],
      tags: ['plat', 'hiver']
    }),
    new Recipe({
      _id: 'sandwich',
      name: 'Sandwich',
      image: 'assets/images/panini.jpg',
      servings: 1,
      complexity: 'simple',
      cookingTime: 15,
      ingredientUsages: [],
      tags: ['plat']
    }),
    new Recipe({
      _id: 'salade',
      name: 'Salade',
      image: 'assets/images/salade.jpg',
      servings: 2,
      complexity: 'medium',
      cookingTime: 30,
      ingredientUsages: [],
      tags: ['plat', 'ete']
    })
  ],

  tags: [
    new RecipeTag({
      _id: 'entree',
      name: 'Entrée'
    }),
    new RecipeTag({
      _id: 'plat',
      name: 'Plat'
    }),
    new RecipeTag({
      _id: 'dessert',
      name: 'Dessert'
    }),
    new RecipeTag({
      _id: 'hiver',
      name: 'Hiver'
    }),
    new RecipeTag({
      _id: 'ete',
      name: 'Été'
    }),
    new RecipeTag({
      _id: 'gouter',
      name: 'Goûter'
    }),
    new RecipeTag({
      _id: 'vegetarien',
      name: 'Végétarien'
    }),
    new RecipeTag({
      _id: 'poisson',
      name: 'Poisson'
    })
  ],
  planningDays: [
    new PlanningDay({
      _id: '2019-06-15',
      date: '2019-06-15',
      recipeUsages: [
        new RecipeUsage({
          recipeId: 'pizza',
          servings: 2,
          meal: 'none'
        })
      ]
    }),
    new PlanningDay({
      _id: '2019-06-16',
      date: '2019-06-16',
      recipeUsages: [
        new RecipeUsage({
          recipeId: 'pizza',
          servings: 1,
          meal: 'lunch'
        }),
        new RecipeUsage({
          recipeId: 'galettes',
          servings: 2,
          meal: 'dinner'
        })
      ]
    }),
    new PlanningDay({
      _id: '2019-06-17',
      date: '2019-06-17',
      recipeUsages: [
        new RecipeUsage({
          recipeId: 'omelette',
          servings: 2,
          meal: 'lunch'
        })
      ]
    })
  ]
}
