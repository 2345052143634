<template lang="pug">
.mp-page.mp-page--small
  h4.mp-section-title About

  p.
    Meal Planner \#{{ application.version }} {{ application.date }}
</template>

<script>
import { applicationVersion, applicationDate } from '@/constants'

export default {
  name: 'About',
  computed: {
    application () {
      return {
        version: applicationVersion,
        date: applicationDate
      }
    }
  }
}
</script>
