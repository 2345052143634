import fetch from 'node-fetch'
import PouchDB from 'pouchdb'

import logger from '@/logger'
import { sleep } from '@/util'

export class ApplicationError extends Error {
  constructor (options) {
    super(options.message)
    this.name = 'ApplicationError'
  }
}

export class UnauthorizedError extends ApplicationError {
  constructor (options) {
    super(options)
    this.name = 'UnauthorizedError'
  }
}

// timeout, dns, offline
export class ConnectionError extends ApplicationError {
  constructor (options) {
    super(options)
    this.name = 'ConnectionError'
  }
}

export class CouchDBError extends ApplicationError {
  constructor (options) {
    super(options)
    this.name = 'CouchDBError'
  }
}

export class UnknownServerError extends ApplicationError {
  constructor (options) {
    super(options)
    this.name = 'UnknownServerError'
  }
}

/*
Server permet de communiquer avec le serveur afin de
synchroniser les données en dehors du navigateur.
Cela permet d'utiliser l'application avec plusieurs périphériques.
*/
export class Server {
  constructor (options = {}) {
    if (!options.apiURL) {
      throw new Error('MealPlannerServer: missing param apiURL')
    }
    this.apiURL = options.apiURL
    this.authToken = ''
    this.events = new EventTarget()
    this.state = {
      online: false,
      authenticated: false
    }
    window.server = this

    this.remoteDatabase = new PouchDB(`${this.apiURL}/database`, {
      fetch: async (url, opts) => {
        opts.headers.set('authorization', this.authToken)
        try {
          const res = await fetch(url, opts)
          if (res.status === 500) {
            throw new Error('wrong fetch status')
          }
          if (res.status === 404 && !url.includes('database/_local/')) {
            const body = await res.json()
            // 404 with missing is not an error
            if (body.reason !== 'missing') {
              throw new Error('wrong fetch status')
            }
          }
          this.state.online = true
          this.events.dispatchEvent(new Event('online'))
          return res
        } catch (err) {
          // errors mean that we are offline or unauthenticated
          console.log('fetch error, stopping sync', err)
          if (this.liveSync) {
            this.liveSync.cancel()
            await this.updateConnectionStatus()
            if (!this.state.online) {
              this.events.dispatchEvent(new Event('offline'))
            } else if (!this.state.authenticated) {
              this.events.dispatchEvent(new Event('unauthenticated'))
            } else {
              console.log('unknown sync error', err)
            }
          }
          throw err
        }
      }
    })
    this.liveSync = null
  }

  setAuthToken (authToken) {
    this.authToken = authToken
  }

  async register (email, password) {
    logger.debug('register')
    const data = await this.requestJson('post', 'register', {
      email,
      password
    })
    return data.token
  }

  async login (email, password) {
    logger.debug('login')
    const data = await this.requestJson('post', 'login', {
      email,
      password
    })
    return data.token
  }

  async compact () {
    logger.debug('compact')
    await this.authRequestJson('post', 'database/_compact')
  }

  async logout () {
    logger.debug('logout')
    if (this.liveSync) {
      this.liveSync.cancel()
    }
    await sleep(100)
    this.setAuthToken(null)
  }

  // https://pouchdb.com/guides/replication.html
  async fetchServerContent (localDatabase) {
    if (!localDatabase) {
      throw new Error('MealPlannerServer.fetchServerContent: missing param "localDatabase"')
    }
    await this.requireAuth()
    logger.debug('Synchronizing local database with the remote database')

    return new Promise((resolve, reject) => {
      this.remoteDatabase.replicate.to(localDatabase)
        .on('complete', resolve)
        .on('change', info => console.log(info))
        .on('error', err => {
          if (err.error === 'unauthorized') {
            reject(new UnauthorizedError({
              message: 'Your session has expired'
            }))
          } else if (err.message === 'Failed to fetch') {
            reject(new UnknownServerError({
              message: 'Not connected to internet'
            }))
          }
          // other errors are couchdb errors
          resolve()
        })
    })
  }

  async startSynchronization (localDatabase, onRemoteChange) {
    if (!localDatabase) {
      throw new Error('MealPlannerServer.startSynchronization: missing param "localDatabase"')
    }
    await this.requireAuth()

    return new Promise((resolve, reject) => {
      if (this.liveSync) {
        this.liveSync.cancel()
      }
      this.liveSync = localDatabase.sync(this.remoteDatabase, {
        live: true,
        retry: true,
        heartbeat: false, // disable infinite poll
        batch_size: 100,
        batches_limit: 10
      })
        .on('change', change => {
          console.log('change', change)
          if (change.direction === 'pull') {
            console.log('received remote documents')
            onRemoteChange(change.change)
          }
        })
        .on('paused', err => {
          // replication paused (e.g. replication up to date, user went offline)
          console.log('sync paused', err)
          resolve()
        })
        .on('active', () => {
          // replicate resumed (e.g. new changes replicating, user went back online)
          console.log('sync active')
          resolve()
        })
        .on('denied', err => {
          // a document failed to replicate (e.g. due to permissions)
          console.log('denied error', err)
        })
        .on('complete', info => {
          // handle complete
          console.log('sync complete', info)
        })
        .on('error', err => {
          // totally unhandled error (shouldn't happen)
          console.log('sync error', err)
          // passer synchronisation en mode offline (indiquer à l'utilisateur)
          // réessayer régulièrement la synchronisation pour
          reject(err)
        })
    })
  }

  async authRequestJson (method, url, body, headers = {}) {
    const res = await this.authRequest(method, url, body, {
      'Content-Type': 'application/json',
      ...headers
    })
    await this.handleErrors(res)
    await this.ensureJsonResponse(res)
    return res.json()
  }

  async requestJson (method, url, body, headers = {}) {
    const res = await this.request(method, url, body, {
      'Content-Type': 'application/json',
      ...headers
    })
    await this.handleErrors(res)
    await this.ensureJsonResponse(res)
    return res.json()
  }

  async authRequest (method, url, body, headers = {}) {
    headers.Authorization = this.authToken
    return this.request(method, url, body, headers)
  }

  request (method, url, body, headers) {
    return fetch(`/api/${url}`, {
      method: method,
      body: body ? (body instanceof FormData ? body : JSON.stringify(body)) : null,
      headers: {
        Accept: '*/*',
        ...headers
      }
    })
  }

  // asserts that the response is ok
  async handleErrors (res) {
    if (!res.ok) {
      await this.ensureJsonResponse(res)
      const errorMessage = (await res.json()).message || 'Une erreur est survenue'
      if (res.status === 400) {
        throw new ApplicationError({
          message: errorMessage
        })
      }
      if (res.status === 401) {
        throw new UnauthorizedError({
          message: errorMessage
        })
      }
      throw new UnknownServerError({
        message: errorMessage
      })
    }
  }

  async ensureJsonResponse (res) {
    const contentType = res.headers.get('content-type')
    if (!contentType || !contentType.startsWith('application/json')) {
      throw new UnknownServerError({
        message: 'Oops! There seems to be a problem with the server configuration. Please contact an administrator.',
        additionnalMessage: await res.text()
      })
    }
  }

  async requireAuth () {
    if (!this.authToken) {
      throw new Error('No authenticated account')
    }
    await this.updateConnectionStatus()
    if (!this.state.online) {
      this.events.dispatchEvent(new Event('offline'))
      throw new Error('Your are offline')
    }
    if (!this.state.authenticated) {
      this.events.dispatchEvent(new Event('unauthenticated'))
      throw new Error('Your are not authenticated')
    }
  }

  async updateConnectionStatus () {
    this.state.online = await this.validateServerConnection()
    if (this.state.online) {
      this.state.authenticated = await this.validateAuthentication()
    }
  }

  async validateAuthentication () {
    logger.debug('validateAuthentication')
    try {
      const res = await this.authRequest('get', 'session')
      await this.handleErrors(res)
      return true
    } catch (err) {
      return false
    }
  }

  async validateServerConnection () {
    logger.debug('validateServerConnection')
    try {
      const res = await this.request('get', '')
      await this.handleErrors(res)
      return true
    } catch (err) {
      return false
    }
  }
}
