import logger from '@/logger'
import * as _ from '@/util'

function filterEntityToMap (acc, entity) {
  acc[entity._id] = entity
  return acc
}

export const ShoppingList = class ShoppingList {
  constructor (options) {
    this.from = options.from
    this.to = options.to
    this.planningDays = options.planningDays

    this.ingredientCategories = []
    this.recipes = []
    this.thingsToBuy = []
  }

  generate (library) {
    this.thingsToBuy = library.thingsToBuy.items.map(item => {
      return {
        name: item,
        checked: false
      }
    })

    // fetch all the required entities
    const recipesById = library.recipes.reduce(filterEntityToMap, {})
    const ingredientsById = library.ingredients.reduce(filterEntityToMap, {})
    const ingredientCategoriesById = library.ingredientCategories.reduce(filterEntityToMap, {})

    // build the shopping list structure
    const ingredientCategories = {}
    const recipes = {}
    this.planningDays.forEach(planningDay => {
      planningDay.recipeUsages.forEach(recipeUsage => {
        const recipe = recipesById[recipeUsage.recipeId]
        if (!recipe) {
          throw new Error(`Planning day ${planningDay.date} contains a missing recipe with id '${recipeUsage.recipeId}'`)
        }

        let recipeInfo = recipes[recipe._id]
        if (!recipeInfo) {
          recipeInfo = recipes[recipe._id] = {
            recipeName: recipe.name,
            servings: recipeUsage.servings
          }
        } else {
          recipeInfo.servings += recipeUsage.servings
        }

        recipe.ingredientUsages.forEach(ingredientUsage => {
          const ingredient = ingredientsById[ingredientUsage.ingredientId]
          if (!ingredient) {
            throw new Error(`Recipe ${recipe.name} contains a missing ingredient with id '${ingredientUsage.ingredientId}'`)
          }
          if (ingredient.hideInShoppingList) {
            return
          }
          const ingredientCategory = ingredientCategoriesById[ingredient.categoryId]
          if (!ingredientCategory) {
            throw new Error(`Ingredient ${ingredient.name} contains a missing category with id '${ingredient.categoryId}'`)
          }

          let ingredientCategoryMap = ingredientCategories[ingredient.categoryId]
          if (!ingredientCategoryMap) {
            ingredientCategoryMap = {
              ...ingredientCategory,
              ingredients: {}
            }
            ingredientCategories[ingredient.categoryId] = ingredientCategoryMap
          }
          let ingredientMap = ingredientCategoryMap.ingredients[ingredientUsage.ingredientId]
          if (!ingredientMap) {
            ingredientMap = {
              ...ingredient,
              ingredientUsages: {},
              recipes: []
            }
            ingredientCategoryMap.ingredients[ingredientUsage.ingredientId] = ingredientMap
          }
          if (!ingredientMap.ingredientUsages[ingredientUsage.type]) {
            ingredientMap.ingredientUsages[ingredientUsage.type] = 0
          }
          logger.debug(`ShoppingList: - ${ingredientCategory.name} ${ingredient.name} ${ingredientUsage.type} += ${ingredientUsage.quantity}`)
          const ingredientUsageCount = (recipeUsage.servings / recipe.servings) * ingredientUsage.quantity
          ingredientMap.ingredientUsages[ingredientUsage.type] += ingredientUsageCount
          ingredientMap.recipes.push({
            recipeName: recipe.name,
            servings: recipeUsage.servings,
            ingredientUsageCount: ingredientUsageCount,
            ingredientUsageType: ingredientUsage.type
          })
        })
      })
    })

    // group recipes within each ingredient usage
    Object.values(ingredientCategories).forEach(category => {
      category.ingredients = Object.values(category.ingredients).sort((a, b) => _.localeStringComparator(a.name, b.name))
      category.ingredients.forEach(ingredient => {
        const groupedRecipes = ingredient.recipes.reduce((acc, recipeUsage) => {
          if (!acc[recipeUsage.recipeName]) {
            acc[recipeUsage.recipeName] = {
              recipeName: recipeUsage.recipeName,
              servings: 0,
              ingredientUsageCount: 0,
              ingredientUsageType: recipeUsage.ingredientUsageType
            }
          }
          acc[recipeUsage.recipeName].servings += recipeUsage.servings
          acc[recipeUsage.recipeName].ingredientUsageCount += recipeUsage.ingredientUsageCount
          return acc
        }, {})
        ingredient.recipes = Object.values(groupedRecipes).sort((a, b) => _.localeStringComparator(a.recipeName, b.recipeName))
      })
    })

    this.ingredientCategories = Object.values(ingredientCategories)
      .sort((a, b) => a.order - b.order)
    this.recipes = Object.values(recipes).sort((a, b) => _.localeStringComparator(a.recipeName, b.recipeName))
  }
}
