<template lang="pug">
.ingredient-usage(v-if="editable")
  .ingredient-usage__ingredient {{ ingredientName }}
  input.ingredient-usage__quantity(type="number" v-model.number="ingredientUsage.quantity")
  v-select.ingredient-usage__type(label="Type" v-model="ingredientUsage.type" :items="quantityTypes" dense menu-props="auto")
  v-btn(icon title="Remove ingredient" @click="$emit('delete')")
    v-icon mdi-close

.ingredient-usage(v-else)
  | {{ingredientUsage.ingredient.name}} {{ingredientUsage.quantity}}{{ingredientUsage.type}}
</template>

<script>
export default {
  props: {
    ingredientUsage: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      quantityTypes: [
        'g',
        'mL',
        'u',
        'cc',
        'cs'
      ]
    }
  },
  computed: {
    ingredientName () {
      const ingredient = this.$store.state.library.ingredients.find(ingredient => ingredient._id === this.ingredientUsage.ingredientId)
      if (!ingredient) {
        throw new Error(`Missing ingredient ${this.ingredientUsage.ingredientId}. Corrupt database?`)
      }
      return ingredient.name
    }
  }
}
</script>

<style lang="stylus">
.ingredient-usage
  display flex

  &__ingredient
    min-width 100px

  &__quantity
    width 60px
    margin: 0 10px

  &__type
    max-width 60px
    margin: 0 10px
</style>
