import vuetify from './vuetify'

import MpConfirmDialog from '@/components/MpConfirmDialog'
import events from '@/events'

export default {
  install (Vue, options = {}) {
    const dialog = new (Vue.extend(MpConfirmDialog))({
      vuetify
    })
    dialog.$mount(document.body.appendChild(document.createElement('div')))

    function confirm (message, actionFn) {
      dialog.$root.$emit('confirm', message, actionFn)
      return new Promise(resolve => {
        dialog.$root.$on('confirmed', resolve)
      })
    }

    const ui = Object.freeze({
      confirm,
      error: (message) => events.bus.$emit(events.Alert, 'error', message),
      success: (message) => events.bus.$emit(events.Alert, 'success', message)
    })

    Object.defineProperty(Vue.prototype, '$ui', {
      get () {
        return ui
      }
    })
  }
}
