<template lang="pug">
.ingredient-edit.mp-page
  v-btn.mb-4(to="/ingredients" title="Go back" exact)
    v-icon(left) mdi-arrow-left
    | Ingredients

  v-form(v-model="validIngredientForm" v-if="ingredient" @submit.prevent)
    h1.mp-page__title
      | {{ newIngredient ? 'New ingredient' : 'Edit ingredient' }}
      v-spacer
      v-btn(icon large title="Save changes" @click="saveChanges" :disabled="!!newCategory || !validIngredientForm")
        v-icon mdi-check
      v-btn(icon large title="Delete ingredient" @click="deleteIngredient"  :disabled="!!newCategory" v-if="!newIngredient")
        v-icon mdi-delete

    h4.mp-section-title Name
    v-text-field(v-model="ingredient.name" placeholder="ingredient name" :rules="nameRules")

    v-checkbox(v-model="ingredient.hideInShoppingList" label="Hide in the shopping list")

    h4.mp-section-title Category

    v-form(v-if="newCategory" v-model="validNewCategoryForm" @submit.prevent)
      v-layout
        v-text-field(ref="newCategoryName" placeholder="Category name" v-model="newCategory.name" :rules="newCategoryRules" @keyup.enter="saveNewCategory")

        v-btn(icon large title="Create" @click="saveNewCategory" :disabled="!validNewCategoryForm")
          v-icon mdi-plus
        v-btn(icon large title="Cancel" @click="cancelNewCategory")
          v-icon mdi-close

    v-layout(v-else)
      v-select(label="Please select one category"
                v-model="ingredient.categoryId" :rules="categoryRules"
                :items="ingredientCategories" item-text="name" item-value="_id"
                dense menu-props="auto"
                )
      v-btn(icon large title="New category" @click="showNewCategoryForm")
        v-icon mdi-plus
</template>

<script>
import Vue from 'vue'

import { Ingredient } from '../api/models/Ingredient'
import { IngredientCategory } from '../api/models/IngredientCategory'

import * as _ from '@/util'

export default {
  name: 'IngredientEdit',
  props: {
    ingredientSlug: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      // ingredient form
      ingredient: null,
      validIngredientForm: true,
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) => !this.ingredientNames.includes(this.ingredient.slugged) || 'This ingredient already exists'
      ],
      categoryRules: [
        (v) => !!v || 'A category is required'
      ],

      // new category form
      newCategory: null,
      validNewCategoryForm: true,
      newCategoryRules: [
        (v) => !!v || 'Name is required',
        (v) => !this.categorySlugs.includes(this.newCategory.slugged) || 'This category already exists'
      ]
    }
  },
  computed: {
    newIngredient () {
      return !this.ingredientSlug
    },
    ingredientCategories () {
      return this.$store.getters['library/ingredientCategories']
    },
    ingredients () {
      return this.$store.state.library.ingredients
    },
    categorySlugs () {
      return this.ingredientCategories.map(c => c.slug)
    }
  },
  async created () {
    if (this.newIngredient) {
      this.ingredient = new Ingredient()
      this.ingredient.category = this.ingredientCategories[0]
    } else {
      const ingredient = this.ingredients.find(ingredient => ingredient.slug === this.ingredientSlug)
      if (!ingredient) {
        this.$ui.error('This ingredient does not exist')
        await this.$router.push('/ingredients')
        return
      }
      this.ingredient = ingredient.clone()
    }

    // utilisé pour la validation
    this.ingredientNames = this.ingredients.filter(e => e._id !== this.ingredient._id).map(e => _.normalize(e.name))
    this.ingredientCategoryNames = this.ingredientCategories.map(e => _.normalize(e.name))
  },
  methods: {
    async saveChanges () {
      await this.$api[this.newIngredient ? 'addDocument' : 'updateDocument'](this.ingredient)
      await this.$router.push('/ingredients')
    },

    async deleteIngredient () {
      const recipesWithCurrentIngredient = this.$store.state.library.recipes
        .filter(r => r.containsIngredient(this.ingredientSlug))
        .map(r => r.name)
      let message = recipesWithCurrentIngredient.join('\n- ')
      if (message) {
        message = `\n- ${message}`
      }
      message = `Are you sure?\nThere are currently ${recipesWithCurrentIngredient.length} recipe(s) using this ingredient.${message}`
      this.$ui.confirm(message, async () => {
        await this.$api.deleteDocument(this.ingredient)
        await this.$router.push('/ingredients')
        this.$ui.success(`Deleted ingredient ${this.ingredient.name}`)
      })
    },

    showNewCategoryForm () {
      this.newCategory = new IngredientCategory()
      // update the DOM before focusing the input
      Vue.nextTick(() => {
        this.$refs.newCategoryName.focus()
      })
    },
    cancelNewCategory () {
      this.newCategory = null
    },

    async saveNewCategory () {
      if (this.validNewCategoryForm) {
        const { id } = await this.$api.addDocument(this.newCategory)
        this.ingredient.categoryId = id
        this.cancelNewCategory()
      }
    }

  }
}
</script>

<style lang="stylus">
.ingredient-edit
  width 500px
</style>
