<template lang="pug">
v-snackbar(bottom elevation="8" :value="updateExists" :timeout="-1" color="primary")
  | An update is available. Please reload the application.

  template(#action)
    v-btn(icon title="Reload the application" @click="reload()")
      v-icon mdi-refresh
</template>

<script>
export default {
  data () {
    return {
      refreshing: false,
      registration: null,
      updateExists: false
    }
  },
  created () {
    document.addEventListener('swUpdated', this.onUpdateAvailable, { once: true })

    // Refresh all open app tabs when a new service worker is installed.
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.refreshing) return
        this.refreshing = true
        window.location.reload()
      })
    }

    window.test = this
  },
  destroyed () {
    document.removeEventListener('swUpdated', this.onUpdateAvailable)
  },
  methods: {
    onUpdateAvailable (event) {
      this.registration = event.detail
      this.updateExists = true
    },

    reload () {
      this.updateExists = false

      // Protect against missing registration.waiting.
      if (!this.registration || !this.registration.waiting) {
        return
      }

      this.registration.waiting.postMessage('skipWaiting')
    }
  }
}
</script>
