<template lang="pug">
.recipe-photo(:class="{'recipe-photo--header': header}")
  .recipe-photo__view(:style="{'background-image': `url(${photoURI})`}")
  template(v-if="editable")
    v-btn.recipe-photo__delete-btn(v-if="!empty"
                                   fab absolute top right
                                   @click="deletePhoto()"
                                   )
      v-icon mdi-close
    .recipe-photo__dropzone(:class="{'recipe-photo__dropzone--active': droppingPhoto}"
                            @click="triggerInputEvent($event)"
                            @drop.prevent="onFileDrop($event)"
                            @dragover.prevent
                            @dragenter.prevent="droppingPhoto = true"
                            @dragleave.prevent="droppingPhoto = false"
                            )
    input.recipe-photo__dropinput(ref="input" type="file" accept="image/*;" @change="onFileInput($event)")

  v-dialog(v-model="pendingPhotoToCrop" fullscreen hide-overlay @keydown.esc="cancelPhotoCrop()")
    v-card
      v-toolbar
        v-btn(icon large @click="cancelPhotoCrop()")
          v-icon(medium) mdi-close
        v-toolbar-title Photo cropping
        v-spacer
        v-toolbar-items
          v-btn(color="primary" title="Save" @click="updatePhoto()") Save

      .cropper-parent
        cropper(ref="cropper"
                class="cropper"
                image-class="cropper__image"
                :src="pendingPhotoToCropURL"
                :stencilProps="stencilProps"
                v-if="pendingPhotoToCrop"
                )
</template>

<script>
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

import { cacheGet } from '@/api/attachment-url-cache'
import * as _ from '@/util'

const defaultRecipePhoto = '/img/empty_plate.png'
/*
5 states:
- no photo on the recipe, no photo added
- no photo on the recipe, new photo added
- a photo on the recipe, same photo
- a photo on the recipe, photo deleted
- a photo on the recipe, new photo added
*/
export default {
  components: {
    Cropper
  },
  props: {
    recipe: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    },
    header: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      droppingPhoto: false,
      pendingPhotoToCrop: null,
      stencilProps: {
        aspectRatio: 3 / 2,
        linesClasses: {
          default: 'cropper__line',
          hover: 'cropper__line--hover'
        },
        handlersClasses: {
          default: 'cropper__handler'
        }
      }
    }
  },
  computed: {
    photoURI () {
      // if a photo change is requested
      if (this.recipe.pendingPhotoChange) {
        return this.recipe.pendingPhotoChange.photo ? URL.createObjectURL(this.recipe.pendingPhotoChange.photo) : defaultRecipePhoto
      }
      return this.recipe._attachments.photo ? cacheGet(this.recipe._attachments.photo) : defaultRecipePhoto
    },
    empty () {
      return this.photoURI === defaultRecipePhoto
    }
  },
  created () {
    if (this.editable) {
      this.$set(this.recipe, 'pendingPhotoChange', null)
    }
  },
  methods: {
    triggerInputEvent (event) {
      this.$refs.input.click(event)
    },
    onFileDrop (event) {
      this.setPhotoCrop(event.dataTransfer.files[0])
    },
    onFileInput (event) {
      const photo = event.target.files[0]
      if (photo) {
        this.setPhotoCrop(photo)
        event.target.value = ''
      }
    },
    setPhotoCrop (photo) {
      this.pendingPhotoToCrop = photo
      this.pendingPhotoToCropURL = URL.createObjectURL(photo)
    },
    async updatePhoto () {
      const { canvas } = this.$refs.cropper.getResult()
      const photoBlob = await _.resizeImage(canvas, 1920)
      this.$emit('change', {
        type: 'update',
        photo: photoBlob
      })
      this.cancelPhotoCrop()
    },
    cancelPhotoCrop () {
      this.pendingPhotoToCrop = null
    },
    deletePhoto () {
      this.$emit('change', {
        type: 'delete'
      })
    }
  }
}
</script>

<style lang="stylus">
.recipe-photo
  display flex
  min-height 264px
  position relative

  &--header
    min-height 200px
    height calc(100vh - 300px)
    max-height 400px

  &__view
    flex 1
    background-position 50%
    background-size cover

  &__delete-btn
    top 16px !important // fix vuetify negative offset

  &__dropzone
    cursor pointer
    position absolute
    left 0
    right 0
    top 0
    bottom 0
    margin 40px
    border 5px dashed rgb(103, 103, 103)
    border-radius 40px
    transition .3s cubic-bezier(0.4, 0, 0.2, 1)

    &:hover
    &--active
      border-color rgb(37, 172, 25)
      transform scale(1.015)

  &__dropinput
    position absolute
    visibility hidden
    top 0
    left 0
    height 0
    width 0

.cropper-parent
  height calc(100vh - 64px)
  display flex
  align-items flex-start
  justify-content center

.cropper
  &__line
    border-style dashed
    border-color #3fb37f

  &__line--hover
    border-color #36cc89

  &__handler
    background #36cc89
    border 1px solid #3fb37f

</style>
