<template lang="pug">
v-container.shoppinglist
  div(v-if="shoppingList")
    div(v-if="shoppingList.ingredientCategories.length === 0") No recipes matched the chosen period. Maybe the period is wrong or you forgot to add the recipes?
    div(v-else)
      v-checkbox(v-model="hideCheckedItems" label="Hide checked items")

      div(v-for="(category, categoryIndex) in shoppingList.ingredientCategories" :key="categoryIndex")
        h4.shoppinglist__category-title {{ category.name }}
        .shoppinglist__category
          .shoppinglist__ingredient(v-for="(ingredient, ingredientIndex) in category.ingredients" :key="ingredientIndex")
            template(v-if="!ingredient.checked || !hideCheckedItems")
              v-checkbox(v-model="ingredient.checked" hide-details)
              div(:class="{'shoppinglist__ingredient--checked': ingredient.checked}")
                v-tooltip(bottom)
                  template(v-slot:activator="{ on: tooltipActivator }")
                    .shoppinglist__ingredient-title(v-on="tooltipActivator") {{ ingredient.name }}&nbsp;:
                    span(v-for="(quantity, unit) in ingredient.ingredientUsages" :key="unit")  {{ quantity | roundNumber }}&nbsp;{{ unit }}

                  div(v-for="recipe in ingredient.recipes") {{ recipe.ingredientUsageCount }} {{ recipe.ingredientUsageType }} <span style="font-size: 1.4em">&rarr;</span> {{ recipe.servings }} {{ recipe.recipeName }}

      h3.shoppinglist__recipes-title Other things to buy
      .shoppinglist__category
        .shoppinglist__ingredient(v-for="(thingToBuy, index) in thingsToBuy" :key="index")
          template(v-if="!thingToBuy.checked || !hideCheckedItems")
            v-checkbox(v-model="thingToBuy.checked" hide-details)
            div(:class="{'shoppinglist__ingredient--checked': thingToBuy.checked}")
              .shoppinglist__ingredient-title {{ thingToBuy.name }}

      h4 Add a new item

      v-form.shoppinglist__newitem-form(ref="newItemForm" v-model="validNewItemForm" lazy-validation @submit.prevent="addNewItem(newItem)")
        v-text-field(v-model="newItem" label="New item" required placeholder="chocolate..." :rules="newItemRules" @blur="onNewItemBlur()")
        v-btn(type="submit" icon color="primary" title="Add a new item" :disabled="!validNewItemForm")
          v-icon mdi-plus

      h3.shoppinglist__recipes-title Recipes used in this shopping list
      .shoppinglist__recipes-list
        .shoppinglist__recipe-name(v-for="recipeUsage in shoppingList.recipes")
          | {{ recipeUsage.recipeName }} : {{ recipeUsage.servings }}

</template>

<script>
export default {
  filters: {
    roundNumber (value) {
      if (value > 10) {
        return Math.round(value)
      }
      return new Intl.NumberFormat().format(value)
    }
  },
  props: {
    shoppingList: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      hideCheckedItems: false,

      validNewItemForm: true,
      newItem: '',
      newItemRules: [
        (v) => !!v || 'Name is required'
      ],
      additionalThingsToBuy: []
    }
  },
  computed: {
    thingsToBuy () {
      return this.shoppingList.thingsToBuy.concat(this.additionalThingsToBuy)
    }
  },
  methods: {
    onNewItemBlur () {
      this.$refs.newItemForm.resetValidation()
    },
    addNewItem (newItem) {
      if (this.$refs.newItemForm.validate()) {
        this.additionalThingsToBuy.push({
          name: newItem,
          checked: false
        })
        this.$refs.newItemForm.reset()
      }
    }
  }
}
</script>

<style lang="stylus">
.shoppinglist

  &__category-title
    background-color: silver
    margin-top: 12px
    margin-bottom: 6px
    padding: .5em
    padding-left: 1em

  &__category
    column-count 3

    @media (max-width 780px)
      column-count 2

  &__ingredient
    break-inside avoid // very important otherwise chrome breaks elements to even columns
    display flex
    align-items flex-end

    @media (max-width 540px)
      font-size .9em

    &--checked
      text-decoration line-through

    .v-input--selection-controls
      margin-top 0

  &__ingredient-title
    display: inline

  &__recipes-title
    margin-top: 2em

  &__recipes-list
    margin-top: 1em

  &__recipe-name
    display: inline-block
    margin-right: 2em

  &__newitem-form
    display inline-flex
    align-items center

</style>
