<template lang="pug">
.mp-meal-chip(:class="color" :title="label")
</template>

<script>
import { Meals } from '@/api/models/RecipeUsage'

export default {
  props: {
    type: {
      type: String,
      required: true
    }
  },
  computed: {
    color () {
      return Meals[this.type].color
    },
    label () {
      return Meals[this.type].label
    }
  }
}
</script>

<style lang="stylus">
.mp-meal-chip
  min-width: 5px
  display: inline-block
  margin-top: 2px
  margin-bottom: 2px
  margin-left: 2px

  @media (max-width 540px)
    min-width: 3px
</style>
