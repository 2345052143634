<template lang="pug">
.mp-alerts(@alert="updateAlert")
  v-alert.mp-alerts__alert(v-model="showAlert"
                           :type="messageType"
                           transition="slide-y-transition"
                          )
    | {{ message }}
</template>

<script>
import events from '@/events'
import logger from '@/logger'

export default {
  data () {
    return {
      showAlert: false,
      message: '',
      messageType: 'error'
    }
  },
  created () {
    events.bus.$on(events.Alert, this.updateAlert)
  },
  destroyed () {
    events.bus.$off(events.Alert, this.updateAlert)
  },
  methods: {
    handleErrorEvent (event) {
      event.preventDefault()
      logger.error(event.error || event.message)
      this.updateAlert('error', event.message)
    },
    updateAlert (messageType, message) {
      this.messageType = messageType
      this.message = message
      this.showAlert = true
      clearTimeout(this.messageTimeout)
      this.messageTimeout = setTimeout(() => {
        this.showAlert = false
      }, 3000)
    }
  }
}
</script>

<style lang="stylus">
.mp-alerts
  position: fixed
  left: 50%
  transform: translateX(-50%)
  z-index: 999

  &__alert
    min-width: 200px
    opacity: 0.95
</style>
