<template lang="pug">
.mp-recipe-usage
  mp-meal-chip(:type="recipeUsage.meal")
  .ml-1 {{ recipeUsage.servings }} {{ recipeUsage.recipe.name }}
</template>

<script>
import { Meals } from '@/api/models/RecipeUsage'
import MpMealChip from '@/components/MpMealChip'

export default {
  components: {
    MpMealChip
  },
  props: {
    recipeUsage: {
      type: Object,
      required: true
    }
  },
  computed: {
    color () {
      return Meals[this.recipeUsage.meal].color
    },
    label () {
      return Meals[this.recipeUsage.meal].label
    }
  }
}
</script>

<style lang="stylus">
.mp-recipe-usage
  display flex
  text-align left
</style>
