import { Entity } from './Entity'

export const PlanningDay = class PlanningDay extends Entity {
  /*
  properties:
    date: string 2017-09-06
    recipeUsages: []RecipeUsage
  */
  static fields () {
    return {
      date: '0000-00-00',
      recipeUsages: []
    }
  }

  get kind () {
    return 'PlanningDay'
  }

  get slugged () {
    return this.date
  }

  addRecipeUsage (recipeUsage) {
    this.recipeUsages.push(recipeUsage)
    return this
  }

  listRecipeUsages (state) {
    return this.recipeUsages.map(usage => {
      const recipe = state.library.recipes.find(recipe => recipe._id === usage.recipeId)
      if (!recipe) {
        throw new Error(`Missing recipe ${usage.recipeId}. Corrupt database?`)
      }
      return {
        ...usage,
        recipe
      }
    })
  }

  containsRecipe (recipeId) {
    return this.recipeUsages.some(usage => usage.recipeId === recipeId)
  }

  removeRecipe (recipeId) {
    this.recipeUsages = this.recipeUsages.filter(usage => usage.recipeId !== recipeId)
    return this
  }

  removeRecipeUsage (recipeUsageIndex) {
    this.recipeUsages.splice(recipeUsageIndex, 1)
    return this
  }
}
