<template lang="pug">
v-dialog(:value="true" eager persistent max-width="600")
  v-card
    v-card-title.headline.grey.lighten-2(primary-title) New ingredient
    v-card-text
      v-form(ref="ingredientForm" v-model="validIngredientForm" @submit.prevent)
        h4.mp-section-title Name
        v-text-field(ref="ingredientName" v-model="ingredient.name" placeholder="ingredient name" :rules="nameRules")

        h4.mp-section-title Category
        v-form(v-if="newCategory" v-model="validNewCategoryForm" @submit.prevent)
          v-layout
            v-text-field(ref="newCategoryName" placeholder="Category name" v-model="newCategory.name" :rules="newCategoryRules" @keyup.enter="saveNewCategory")

            v-btn(icon large title="Create" @click="saveNewCategory" :disabled="!validNewCategoryForm")
              v-icon mdi-plus
            v-btn(icon large title="Cancel" @click="cancelNewCategory")
              v-icon mdi-close

        v-layout(v-else)
          v-select(label="Please select one category"
                   v-model="ingredient.categoryId" :rules="categoryRules"
                   :items="ingredientCategories" item-text="name" item-value="_id"
                   dense menu-props="auto"
                  )
          v-btn(icon large title="New category" @click="showNewCategoryForm")
            v-icon mdi-plus

    v-divider
    v-card-actions
      v-spacer
      v-btn(text @click="closeDialog") Cancel
      v-btn(text color="primary" title="Create ingredient" @click="createIngredient" :disabled="!!newCategory || !validIngredientForm")
        | Create
</template>

<script>
import Vue from 'vue'

import { Ingredient } from '../api/models/Ingredient'
import { IngredientCategory } from '../api/models/IngredientCategory'

import * as _ from '@/util'

export default {
  props: {
    initialName: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      // ingredient form
      validIngredientForm: true,
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) => !this.ingredientNames.includes(this.ingredient.slugged) || 'This ingredient already exists'
      ],
      categoryRules: [
        (v) => !!v || 'A category is required'
      ],

      // new category form
      newCategory: null,
      validNewCategoryForm: true,
      newCategoryRules: [
        (v) => !!v || 'Name is required',
        (v) => !this.categorySlugs.includes(this.newCategory.slugged) || 'This category already exists'
      ]
    }
  },
  computed: {
    ingredientCategories () {
      return this.$store.getters['library/ingredientCategories']
    },
    ingredients () {
      return this.$store.state.library.ingredients
    },
    categorySlugs () {
      return this.ingredientCategories.map(c => c.slug)
    }
  },
  created () {
    this.ingredient = new Ingredient()
    this.ingredient.name = this.initialName
    this.ingredient.category = this.ingredientCategories[0]

    // used for validation
    this.ingredientNames = this.ingredients.map(e => _.normalize(e.name))
    this.ingredientCategoryNames = this.ingredientCategories.map(e => _.normalize(e.name))
  },
  mounted () {
    Vue.nextTick(() => {
      this.$refs.ingredientName.focus()
    })
  },
  methods: {
    closeDialog () {
      this.$emit('close')
    },

    async createIngredient () {
      const { id } = await this.$api.addDocument(this.ingredient)
      this.$emit('close', id)
    },

    showNewCategoryForm () {
      this.newCategory = new IngredientCategory()
      // update the DOM before focusing the input
      Vue.nextTick(() => {
        this.$refs.newCategoryName.focus()
      })
    },
    cancelNewCategory () {
      this.newCategory = null
    },

    async saveNewCategory () {
      if (this.validNewCategoryForm) {
        const { id } = await this.$api.addDocument(this.newCategory)
        this.ingredient.categoryId = id
        this.cancelNewCategory()
      }
    }

  }
}
</script>

<style lang="stylus">
</style>
