<template lang="pug">
section
  mp-recipe-photo(v-if="edit" :recipe="editedRecipe" header editable @change="updatePendingPhotoChange($event)")
  mp-recipe-photo(v-else :recipe="recipe" header)

  .mp-recipe(:class="{ 'mp-recipe--cook': cook }")

    .mp-recipe__header
      v-text-field.recipe-title-edit(v-if="edit"
                                      v-autosize rows="1"
                                      v-model="editedRecipe.name"
                                      placeholder="recipe name" :rules="nameRules"
                                      :autofocus="newRecipe"
                                    )
      h1.mp-recipe__name(v-else) {{ recipe.name }}

      .mp-recipe__actions
        template(v-if="cook")
          v-btn(icon large title="Stop cooking mode" :to="`/recipes/${recipe.slug}`" exact)
            v-icon mdi-close
        template(v-else-if="edit")
          v-btn(icon large title="Save changes" @click="saveChanges" :disabled="!validForm")
            v-icon mdi-check
          v-btn(icon large title="Cancel" :to="recipe ? `/recipes/${recipe.slug}` : '/recipes'" exact)
            v-icon mdi-close
          v-btn(v-if="recipe" icon large title="Delete recipe" @click="deleteRecipe")
            v-icon mdi-delete
        template(v-else)
          v-btn(icon large title="Edit recipe" :to="`/recipes/${recipe.slug}/edit`")
            v-icon mdi-pencil
          v-btn(icon large title="Enter cooking mode" :to="`/recipes/${recipe.slug}/cook`")
            v-icon mdi-cube-scan

    .mp-recipe__metadatalist(v-if="!cook")
      .mp-recipe__metadata(title="Servings")
        v-icon mdi-account-multiple
        input(v-if="edit" type="number" min="1" max="20" v-model.number="editedRecipe.servings")
        span(v-else)  {{ recipe.servings }}

      .mp-recipe__metadata(title="Cooking time")
        v-icon mdi-timer
        input(v-if="edit" type="number" v-model.number="editedRecipe.cookingTime" )
        span(v-else)  {{ recipe.cookingTime }}

      .mp-recipe__metadata(title="Complexity")
        v-icon mdi-cogs
        select(v-if="edit" v-model="editedRecipe.complexity")
          option(v-for="complexity in recipeComplexities" :value="complexity.key") {{ complexity.label }}
        span(v-else)  {{ recipe.complexity | recipe-complexity }}

    h4.mp-recipe__section-title Ingredients
    ul.mp-list(v-if="edit")
      mp-ingredient-usage.mp-list__item(v-for="(ingredientUsage, index) in editedRecipe.ingredientUsages" :key="ingredientUsage.ingredientId"
                                        :ingredientUsage="ingredientUsage" :editable="true"
                                        @delete="removeIngredientUsage(index)"
                                        )

      .mp-list__item
        v-autocomplete(label="Add ingredient" v-model="newIngredient" :search-input.sync="ingredientSearch"
                        :items="availableIngredients" item-text="name" item-value="_id" :filter="ingredientsFilter"
                        @change="addIngredient")
      mp-new-ingredient-dialog(v-if="showNewIngredientDialog" :initialName="ingredientSearch" @close="handleNewIngredientDialogClose")

    ul.mp-list(v-else)
      mp-ingredient-usage.mp-list__item(v-for="ingredientUsage in ingredientUsages" :key="ingredientUsage.ingredientId"
                                        :ingredientUsage="ingredientUsage"
                                        )

    h4.mp-recipe__section-title Directions
    ol.mp-list(v-if="edit")
      li.mp-list__item.mp-recipe__step(v-for="(direction, index) in editedRecipe.directions" :key="index")
        textarea.mp-recipe__step-input(v-autosize rows="1"
                                        :value="direction" @input="updateDirection(index, $event.target.value)"
                                      )
        v-btn(icon title="Remove direction" @click="removeDirection(index)")
          v-icon mdi-close

      li.mp-list__item.mp-recipe__step
        textarea.mp-recipe__step-input(v-autosize rows="1" placeholder="new direction..."
                                        v-model.trim="newDirection" @keydown.enter.prevent="addNewDirection"
                                      )
        v-btn(icon title="Add direction" @click="addNewDirection")
          v-icon mdi-plus
    ol.mp-list(v-else)
      li.mp-list__item.mp-recipe__step(v-for="direction in recipe.directions") {{ direction }}

    template(v-if="!cook")
      h4.mp-recipe__section-title Links
      ul.mp-list(v-if="edit")
        li.mp-list__item(v-for="(link, index) in editedRecipe.links" :key="index")
          mp-recipe-link.flex(:url="link")
          v-btn(icon title="Remove link" @click="removeLink(index)")
            v-icon mdi-close

        li.mp-list__item
          input.recipe-item-input(type="text" placeholder="new link..."
                                  v-model="newLink" @keydown.enter="addNewLink"
                                  )
          v-btn(icon title="Add link" @click="addNewLink")
            v-icon mdi-plus
      ul.mp-list(v-else)
        li.mp-list__item(v-for="link in recipe.links")
          mp-recipe-link(:url="link")

      h4.mp-recipe__section-title Tags
      ul.mp-list
        template(v-if="edit")
          li.mp-list__item(v-for="(tag, index) in editedTags")
            | {{ tag.name }}
            v-btn(icon title="Remove tag" @click="removeTag(index)")
              v-icon mdi-close

          v-autocomplete(label="Add tag" v-model="newTag"
                          :items="recipeTags" item-text="name" item-value="_id" :filter="slugFilter"
                          @change="addNewTag"
                        )

        template(v-else)
          li.mp-list__item(v-for="tag in tags" :key="tag._id")
            | {{ tag.name }}
</template>

<script>
import { recipeComplexities, Recipe } from '@/api/models/Recipe'
import MpIngredientUsage from '@/components/MpIngredientUsage'
import MpNewIngredientDialog from '@/components/MpNewIngredientDialog'
import MpRecipeLink from '@/components/MpRecipeLink'
import MpRecipePhoto from '@/components/MpRecipePhoto'
import * as _ from '@/util'

const newIngredientKey = '__new-ingredient__'

export default {
  components: {
    MpIngredientUsage,
    MpNewIngredientDialog,
    MpRecipePhoto,
    MpRecipeLink
  },
  props: {
    recipe: {
      type: Object,
      default: null
    },
    edit: {
      type: Boolean,
      default: false
    },
    cook: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      recipeComplexities,
      // edit mode
      editedRecipe: null,
      recipePhoto: null,
      newDirection: '',
      newIngredient: '',
      ingredientSearch: '',
      newLink: '',
      newTag: '',
      validForm: true,
      nameRules: [
        v => !!v || 'Name is required',
        v => !this.recipeSlugs.includes(this.editedRecipe.slugged) || 'This recipe already exists'
      ],
      showNewIngredientDialog: false
    }
  },
  computed: {
    newRecipe () {
      return !this.recipe
    },
    ingredientUsages () {
      return this.recipe ? this.recipe.listIngredientUsages(this.$store.state) : []
    },
    tags () {
      return this.recipe ? this.recipe.listTags(this.$store.state) : []
    },
    recipeTags () {
      return this.$store.state.library.recipeTags.filter(tag => !this.editedRecipe.tagsIds.includes(tag._id))
    },
    usedIngredientsIds () {
      return this.editedRecipe.ingredientUsages.map(usage => usage.ingredientId)
    },
    availableIngredients () {
      const ingredients = this.$store.getters['library/ingredients'].filter(ingredient => !this.usedIngredientsIds.includes(ingredient._id))
      if (this.ingredientSearch) {
        ingredients.push({
          _id: newIngredientKey,
          name: `${this.ingredientSearch} (new ingredient)`
        })
      }
      return ingredients
    },
    editedTags () {
      return this.editedRecipe.listTags(this.$store.state)
    }
  },
  created () {
    this.recipeSlugs = this.$store.state.library.recipes.map(e => e.slug)

    if (this.newRecipe) {
      this.editedRecipe = new Recipe()
    } else {
      this.editedRecipe = this.recipe.clone()
      this.recipeSlugs = this.recipeSlugs.filter(slug => slug !== this.recipe.slug)
    }
  },
  methods: {
    ingredientsFilter (item, queryText, itemText) {
      return item._id === newIngredientKey || this.slugFilter(item, queryText)
    },
    slugFilter (item, queryText, itemText) {
      return item.slug.indexOf(_.normalize(queryText)) > -1
    },

    //
    // edit
    //
    async saveChanges () {
      const { slug } = await this.$api[this.newRecipe ? 'addDocument' : 'updateDocument'](this.editedRecipe)
      await this.$router.push(`/recipes/${slug}`)
    },

    deleteRecipe () {
      this.$ui.confirm('Are you sure?', async () => {
        await this.$api.deleteDocument(this.recipe)
        await this.$router.push('/recipes')
      })
    },

    updatePendingPhotoChange (photoChangeEvent) {
      this.editedRecipe.pendingPhotoChange = photoChangeEvent
    },

    addIngredient (ingredientId) {
      if (ingredientId === newIngredientKey) {
        // open the dialog if it's the special key
        this.showNewIngredientDialog = true
      } else {
        this.editedRecipe.addIngredientUsage({
          ingredientId,
          quantity: 1,
          type: 'u'
        })
      }
      this.$nextTick(() => {
        this.newIngredient = ''
      })
    },
    handleNewIngredientDialogClose (ingredientId) {
      if (ingredientId) {
        this.addIngredient(ingredientId)
      }
      this.showNewIngredientDialog = false
    },
    removeIngredientUsage (index) {
      this.editedRecipe.removeIngredientUsage(index)
    },

    addNewDirection () {
      this.editedRecipe.directions.push(this.newDirection)
      this.newDirection = ''
    },
    updateDirection (index, value) {
      this.$set(this.editedRecipe.directions, index, value)
    },
    removeDirection (index) {
      this.editedRecipe.directions.splice(index, 1)
    },

    addNewLink () {
      this.editedRecipe.links.push(this.newLink)
      this.newLink = ''
    },
    removeLink (index) {
      this.editedRecipe.links.splice(index, 1)
    },

    addNewTag (tagId) {
      this.editedRecipe.tagsIds.push(tagId)
    },
    removeTag (index) {
      this.editedRecipe.tagsIds.splice(index, 1)
    }
  }
}
</script>

<style lang="stylus">
.mp-recipe
  max-width 700px
  margin 0 auto
  padding 1em

  &--cook
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    background-color #fff
    z-index 999
    text-align center
    max-width initial

  &__header
    display flex
    align-items center

  &__name
    flex 1
    margin 0

  &__metadatalist
    padding 10px

  &__metadata
    display inline-block
    padding 5px 10px
    margin 0 10px
    background-color rgba(60, 200, 141, 0.29)
    border-radius 10px

  &__actions
    font-size 3em

  &__section-title
    font-size 1.4em
    margin-top 15px
    margin-bottom 10px

    border-bottom 1px solid rgb(14, 213, 99)
    display inline-block
    padding 5px

  &__step
    counter-increment list

    &:before
      content counter(list)
      font-size 0.9em

  // textarea des directions
  &__step-input
    border 0
    outline 0
    resize none // désactive le redimensionnement navigateur
    flex 1
</style>
