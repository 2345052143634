<template lang="pug">
.ingredients-library
  .ingredients-library__sidebar
    h2.ingredients-library__sidebar-title
      | Ingredients
      v-btn(icon title="New ingredient" to="/ingredients/new")
        v-icon mdi-plus

    .sidebar-filter
      label
        h4.sidebar-filter__title
          v-icon mdi-food-apple
          |  Name
        v-text-field(v-model="libraryFilters.ingredientName" hide-details placeholder="filter by name" clearable)

    .sidebar-filter
      h4.sidebar-filter__title
        v-icon mdi-tag-multiple
        |  Categories

      p(v-if="ingredientCategories.length === 0") No categories yet
      .sidebar-filter__inputchoice(v-for="category in ingredientCategories")
        v-checkbox(v-model="libraryFilters.ingredientCategories" :value="category._id" :label="category.name" hide-details)

  .ingredients-library__content
    .ingredient-card(v-for="ingredient in ingredients" :key="ingredient._id")
      router-link.ingredient-card__link(:to="`/ingredients/${ingredient.slug}`")
        i.ingredient-card__category(v-if="ingredient.category") {{ ingredient.category.name }}
        h3.ingredient-card__title(v-html="ingredient.filteredName")
</template>

<script>
import * as _ from '@/util'

export default {
  data () {
    return {
      libraryFilters: {}
    }
  },
  computed: {
    ingredientCategories () {
      return this.$store.state.library.ingredientCategories
    },
    ingredients () {
      return this.$store.getters['library/ingredientsWithCategories']
    }
  },
  watch: {
    libraryFilters: {
      handler: _.debounce(function (filters) {
        this.onLibraryFiltersChange(filters)
      }, 100),
      deep: true
    }
  },
  created () {
    this.libraryFilters = _.cloneDeep(this.$store.state.library.ingredientsLibrary.filters)
    this.onLibraryFiltersChange = _.debounce(_.skipFirst(function (filters) {
      this.$store.commit('library/updateIngredientsLibraryFilters', filters)
    }), 100)
  }
}
</script>

<style lang="stylus">
.ingredients-library
  display flex
  height 100%
  font-size 1.2em

  &__sidebar
    background-color rgb(220, 233, 228)
    width 250px
    overflow-y auto

  &__sidebar-title
    text-align center

  &__content
    flex 1
    overflow-y auto
    background-color rgb(244, 255, 250)

.sidebar-filter
  padding 0.4em

  &__title
    display block

  &__inputchoice
    display block
    margin-left 1em

.ingredient-card
  position relative
  float left
  width 120px
  height 60px
  display flex
  border 1px solid rgb(233, 246, 240)
  overflow hidden
  text-overflow ellipsis

  &__link
    flex 1
    display flex
    align-items center
    justify-content center

  &__category
    position absolute
    top 0
    left 0
    font-size 0.5em

  &__title
    font-size .9em
    line-height .9em

    span
      color rgb(120, 222, 142)

</style>
