<template lang="pug">
v-dialog(v-model="showDialog" max-width="350")
  v-card
    v-card-title.headline Confirmation
    v-divider
    v-card-text.subtitle-1.confirm-dialog-content(v-html="message")
    v-card-actions
      v-spacer
      v-btn(color="primary" @click="confirm" :loading="loading" :disabled="loading") Yes
      v-btn(@click="cancel" :disabled="loading") No
</template>

<script>
export default {
  data () {
    return {
      message: '',
      actionFn: null,
      loading: false,
      showDialog: false
    }
  },
  created () {
    this.$on('confirm', (message, actionFn) => {
      this.message = message
      this.actionFn = actionFn
      this.showDialog = true
    })
  },
  methods: {
    async confirm () {
      this.loading = true
      try {
        await this.actionFn()
        this.$emit('confirmed', true)
        this.showDialog = false
      } finally {
        this.loading = false
      }
    },
    cancel () {
      this.$emit('confirmed', false)
      this.showDialog = false
    }
  }
}
</script>

<style lang="stylus">
.confirm-dialog-content
  white-space pre-line
</style>
