import PouchDB from 'pouchdb'
import Vue from 'vue'
import Vuex from 'vuex'
import createLogger from 'vuex/dist/logger'

import { Ingredient } from './models/Ingredient'
import { IngredientCategory } from './models/IngredientCategory'
import { PlanningDay } from './models/PlanningDay'
import { Recipe } from './models/Recipe'
import { RecipeTag } from './models/RecipeTag'
import { ThingsToBuyKey, ThingsToBuy } from './models/ThingsToBuy'
import { Server, UnauthorizedError } from './server'

import sampleLibrary from '@/api/models/test/sample-data'
import { buildAccountStore } from '@/api/modules/account'
import { buildLibraryStore } from '@/api/modules/library'
import events from '@/events'
import logger from '@/logger'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'
const lastShoppingListKey = 'mp-lastShoppingList'

/*
API s'occupe de gérer le store vuex et la synchronisation avec le serveur via PouchDB.

PouchDBStore est l'interface vers la base de données PouchDB
qui est responsable d'enregistrer les collections de la library.
*/
export default class API {
  constructor (options) {
    if (options && options.server) {
      this.server = options.server
    } else {
      this.server = new Server({
        apiURL: `${location.origin}/api`
      })
    }

    this.db = new PouchDB('meal-planner')
    if (process.env !== 'production') {
      window.db = this.db
    }

    // try to restart the synchronization if we got disconnected
    document.addEventListener('visibilitychange', () => {
      if (this.authenticate() && !this.isOnline() && !document.hidden) {
        this.restartSynchronization()
      }
    }, false)
  }

  /*
    Charge le store:
    - Va tenter d'effectuer une connexion vers le serveur pour récupérer les données
    - On démarre la synchronisation automatique :
      - quand un changement est fait localement, il est synchronisé sur le serveur
      - quand un changement est fait sur le serveur, il est rappatrié localement, et l'utilisateur est averti, avant de reload la page
    - Va se rabattre sur la bdd locale si la connexion échoue
  */
  async buildVuexStore () {
    this.loadLastShoppingList()
    let accountStoreState = this.loadAccountStoreState()
    this.server.setAuthToken(accountStoreState.token)
    if (accountStoreState.token) {
      logger.info('Token found, user is authenticated')
      try {
        await this.startSynchronization()
        accountStoreState.online = true
      } catch (err) {
        if (err instanceof UnauthorizedError) {
          console.log('error during sync, unauthenticated', err)
          accountStoreState = {}
          events.bus.$emit(events.Alert, 'error', 'Votre session a expiré. Veuillez vous reconnecter.')
        } else {
          console.log('error during sync, offline', err)
          // show the offline banner
          accountStoreState.online = false
        }
      }
    }
    const library = await this.getLibraryState()
    this.store = new Vuex.Store({
      modules: {
        account: buildAccountStore(accountStoreState),
        library: buildLibraryStore(library)
      },
      strict: debug,
      plugins: [createLogger()]
    })
    this.store.commit('library/updateLibraryFilters', this.store.state.library.recipesLibrary.filters)
    this.store.commit('library/updateIngredientsLibraryFilters', this.store.state.library.ingredientsLibrary.filters)
    this.server.events.addEventListener('unauthenticated', () => {
      events.bus.$emit(events.Alert, 'error', 'Votre session a expiré. Veuillez vous reconnecter.')
      events.bus.$emit('unauthenticated')
    })
    this.server.events.addEventListener('online', () => {
      if (!this.store.state.account.online) {
        this.store.commit('account/setOnline', true)
      }
    })
    this.server.events.addEventListener('offline', () => {
      if (this.store.state.account.online) {
        this.store.commit('account/setOnline', false)
      }
    })

    return this.store
  }

  async addDocument (document) {
    logger.info(`Add document: ${document.slug}`)
    const { id } = await this.db.put(document.toPouchDBFormat())
    let rawDocument = await this.db.get(id, {
      attachments: true,
      binary: true
    })
    // recipe-specific
    if (document.pendingPhotoChange) {
      if (document.pendingPhotoChange.type === 'update') {
        await this.db.putAttachment(rawDocument._id, 'photo', rawDocument._rev, document.pendingPhotoChange.photo, document.pendingPhotoChange.photo.type)
      } else { // delete
        await this.db.removeAttachment(rawDocument._id, 'photo', rawDocument._rev)
      }
      rawDocument = await this.db.get(id, {
        attachments: true,
        binary: true
      })
    }
    this.store.commit('library/addDocument', rawDocument)
    return {
      id: rawDocument._id,
      slug: rawDocument.slug
    }
  }

  async updateDocument (document) {
    logger.info(`Update document: ${document.slug}`)
    await this.db.put(document.toPouchDBFormat())
    let rawDocument = await this.db.get(document._id, {
      attachments: true,
      binary: true
    })
    // recipe-specific
    if (document.pendingPhotoChange) {
      if (document.pendingPhotoChange.type === 'update') {
        await this.db.putAttachment(rawDocument._id, 'photo', rawDocument._rev, document.pendingPhotoChange.photo, document.pendingPhotoChange.photo.type)
      } else { // delete
        await this.db.removeAttachment(rawDocument._id, 'photo', rawDocument._rev)
      }
      rawDocument = await this.db.get(document._id, {
        attachments: true,
        binary: true
      })
    }
    this.store.commit('library/updateDocument', rawDocument)
    return {
      id: rawDocument._id,
      slug: rawDocument.slug
    }
  }

  async deleteDocument (document) {
    logger.info(`Remove document: ${document.slug}`)
    await this.db.remove(document)
    this.store.commit('library/deleteDocument', document)

    await Promise.all(
      document.destroy(this.store.state.library)
        .map(updatedDocument => this.updateDocument(updatedDocument))
    )
  }

  async updateThingsToBuy (thingsToBuy) {
    logger.info('Update things to buy')
    await this.db.put(thingsToBuy.toPouchDBFormat())
    const rawDocument = await this.db.get(ThingsToBuyKey)
    this.store.commit('library/updateThingsToBuy', new ThingsToBuy(rawDocument))
  }

  async getLibraryState () {
    const docs = (await this.db.allDocs({
      include_docs: true,
      attachments: true,
      binary: true
    })).rows.map(d => d.doc)
    return {
      ingredientCategories: docs.filter(e => e.kind === 'IngredientCategory').map(e => new IngredientCategory(e)),
      ingredients: docs.filter(e => e.kind === 'Ingredient').map(e => new Ingredient(e)),
      recipeTags: docs.filter(e => e.kind === 'RecipeTag').map(e => new RecipeTag(e)),
      recipes: docs.filter(e => e.kind === 'Recipe').map(e => new Recipe(e)),
      planningDays: docs.filter(e => e.kind === 'PlanningDay').map(e => new PlanningDay(e)),
      thingsToBuy: new ThingsToBuy(docs.find(e => e._id === ThingsToBuyKey))
    }
  }

  authenticate () {
    return this.store.getters['account/authenticated']
  }

  isOnline () {
    return this.store.state.account.online
  }

  async login ({ email, password }) {
    const token = await this.server.login(email, password)
    if (email !== localStorage.getItem('mp-lastaccount')) {
      await this.db.destroy()
      this.db = new PouchDB('meal-planner')
    }
    this.server.setAuthToken(token)
    await this.startSynchronization()

    const library = await this.getLibraryState()
    this.store.commit('library/loadLibrary', library)
    this.store.commit('account/login', { email, token })
    localStorage.setItem('mp-lastaccount', email)
  }

  async logout () {
    logger.info('MealPlannerStore:logout')
    this.server && await this.server.logout()
    this.store.commit('account/logout')
  }

  async register ({ email, password }) {
    await this.server.register(email, password)
  }

  onSyncRemoteChange (change) {
    console.log('will update docs', change.docs)
    change.docs.forEach(rawDocument => {
      console.log('update doc', rawDocument._id)
      this.store.commit('library/updateDocument', rawDocument)
    })
  }

  // import a state
  importState (state) {
    // flattens the state into one collection and converts the documents
    const flatDocs = Object.values(state).reduce((acc, v) => acc.concat(v), []).map(doc => doc.toPouchDBFormat())
    logger.info(`Importing ${flatDocs.length} documents`)
    return this.db.bulkDocs(flatDocs)
  }

  async clearLibrary () {
    await this.resetLibrary()
    await this.hydrateStoreLibrary()
  }

  /*
  Reset the store to an empty state
  */
  async resetLibrary () {
    // delete all documents
    const result = await this.db.allDocs({ include_docs: true })
    await this.db.bulkDocs(result.rows.map(row => {
      row.doc._deleted = true
      return row.doc
    }))
    await Promise.all([
      this.server.compact(),
      this.db.compact()
    ])
  }

  /*
    Hydrate the library store with data from pouchdb
  */
  async hydrateStoreLibrary () {
    this.store.commit('library/loadLibrary', await this.getLibraryState())
  }

  /*
  Fetch the account store state from the local storage or creates a new one.
  */
  loadAccountStoreState () {
    const existingAccountStoreDump = localStorage.getItem('mp-authentication')
    return existingAccountStoreDump ? JSON.parse(existingAccountStoreDump) : {}
  }

  async loadSampleLibrary () {
    await this.resetLibrary()
    await this.importState(sampleLibrary)
    await this.hydrateStoreLibrary()
  }

  async exportLibrary () {
    const documents = await this.db.allDocs({
      include_docs: true,
      attachments: true
    })
    return documents.rows.map(row => {
      delete row.doc._rev
      return row.doc
    })
  }

  async importLibrary (dumpStr) {
    const documents = JSON.parse(dumpStr)
    await this.resetLibrary()
    logger.info(`Importing ${documents.length} documents`)

    const ingredientCategories = documents.filter(e => e.kind === 'IngredientCategory')
    const ingredientCategoriesIds = (await this.db.bulkDocs(ingredientCategories))
      .reduce((acc, res, index) => {
        acc[ingredientCategories[index]._id] = res.id
        return acc
      }, {})

    const ingredients = documents.filter(e => e.kind === 'Ingredient')
    ingredients.forEach(ingredient => {
      ingredient.categoryId = ingredientCategoriesIds[ingredient.categoryId]
    })
    const ingredientsIds = (await this.db.bulkDocs(ingredients))
      .reduce((acc, res, index) => {
        acc[ingredients[index]._id] = res.id
        return acc
      }, {})

    const recipeTags = documents.filter(e => e.kind === 'RecipeTag')
    const recipeTagsIds = (await this.db.bulkDocs(recipeTags))
      .reduce((acc, res, index) => {
        acc[recipeTags[index]._id] = res.id
        return acc
      }, {})

    const recipes = documents.filter(e => e.kind === 'Recipe')
    recipes.forEach(recipe => {
      recipe.ingredientUsages.forEach(ingredientUsage => {
        ingredientUsage.ingredientId = ingredientsIds[ingredientUsage.ingredientId]
      })
      recipe.tagsIds = recipe.tagsIds.map(id => recipeTagsIds[id])
    })
    const recipesIds = (await this.db.bulkDocs(recipes))
      .reduce((acc, res, index) => {
        acc[recipes[index]._id] = res.id
        return acc
      }, {})

    const planningDays = documents.filter(e => e.kind === 'PlanningDay')
    planningDays.forEach(planningDay => {
      planningDay.recipeUsages.forEach(recipeUsage => {
        recipeUsage.recipeId = recipesIds[recipeUsage.recipeId]
      })
    })
    await this.db.bulkDocs(planningDays)

    const thingsToBuy = documents.find(e => e._id === ThingsToBuyKey)
    await this.db.bulkDocs([thingsToBuy])

    await this.hydrateStoreLibrary()
  }

  async startSynchronization () {
    logger.info('Fetching server content')
    await this.server.fetchServerContent(this.db)
    logger.info('Starting synchronization')
    await this.server.startSynchronization(this.db, change => this.onSyncRemoteChange(change))
  }

  async restartSynchronization () {
    try {
      await this.startSynchronization()
      this.store.commit('account/setOnline', true)
    } catch (err) {
      this.store.commit('account/setOnline', false)
      throw err
    }
  }

  loadLastShoppingList () {
    const lastShoppingListDump = localStorage.getItem(lastShoppingListKey)
    if (lastShoppingListDump) {
      this.lastShoppingList = JSON.parse(lastShoppingListDump)
    }
    return this.lastShoppingList
  }

  saveLastShoppingList (shoppingList) {
    this.lastShoppingList = shoppingList
    localStorage.setItem(lastShoppingListKey, JSON.stringify(shoppingList))
  }
}
