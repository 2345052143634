<template lang="pug">
v-app
  h1 404
  p The page you are looking for was not found.
  router-link(to="/") Go back to Meal Planner
</template>

<script>
export default {
  name: 'Page404'
}
</script>

<style lang="stylus">

</style>
