export const Meals = {
  lunch: {
    label: 'Lunch',
    value: 'lunch',
    color: 'red darken-2',
    order: 0
  },
  dinner: {
    label: 'Dinner',
    value: 'dinner',
    color: 'indigo',
    order: 1
  },
  none: {
    label: 'None',
    value: 'none',
    color: 'grey',
    order: 2
  }
}

export const RecipeUsage = class RecipeUsage {
  /*
  properties:
    recipeId: int
    servings: int
    meal: enum(string)
  */
  constructor (options) {
    if (!options.servings) {
      throw new Error('RecipeUsage: missing servings property')
    }
    if (!options.recipeId) {
      throw new Error('RecipeUsage: missing recipeId property')
    }
    this.servings = options.servings
    this.recipeId = options.recipeId
    this.meal = options.meal || 'none' // 'none' || 'lunch' || 'dinner'
  }
}
