<template lang="pug">
.planning-day.mp-page
  .mp-row
    v-btn(:to="`/planning/${yearmonth}`" title="Go back")
      v-icon(left) mdi-arrow-left
      | {{ monthLabel }}

    v-btn(icon :to="previousDayLink" title="Previous day")
      v-icon mdi-chevron-left
    v-btn(icon :to="nextDayLink" title="Next day")
      v-icon mdi-chevron-right

    h1.title.ml-4
      | {{ dateLabel }}

  h4.mp-section-title Current recipes
  .planning-day__current-recipes
    .recipe-usage(v-for="(recipeUsage, recipeUsageIndex) in assignedRecipeUsages" :key="recipeUsageIndex")

      .recipe-usage__servings(title="Servings")
        v-btn(icon @click="updateRecipeUsageServings(recipeUsageIndex, recipeUsage.servings - 1)" :disabled="recipeUsage.servings === 1")
          v-icon mdi-minus
        | {{ recipeUsage.servings }}
        v-btn(icon @click="updateRecipeUsageServings(recipeUsageIndex, recipeUsage.servings + 1)" :disabled="recipeUsage.servings === 20")
          v-icon mdi-plus

      .recipe-usage__recipe-name {{ recipeUsage.recipe.name }}

      .recipe-usage__meal(title="Meal")
        v-select.hidden-sm-and-up(:value="recipeUsage.meal" @change="updateRecipeUsageMeal(recipeUsageIndex, $event)" :items="Object.values(Meals)" label="Meal" dense item-text="label" item-value="value")
          template(v-slot:selection="{ item, index }")
           span(:class="item.color.split(' ').map(c => `${c}--text`).join(' ')") {{ item.label }}
        v-radio-group.hidden-xs-only(:value="recipeUsage.meal" @change="updateRecipeUsageMeal(recipeUsageIndex, $event)" row hide-details dense)
          v-radio(v-for="meal in Meals" :key="meal.value"
                  :label="meal.label" :value="meal.value" :color="meal.color"
                  )

      .recipe-usage__actions
        v-btn(icon @click="removeRecipeUsage(recipeUsageIndex)")
          v-icon mdi-close

  .d-flex.align-center
    v-btn.mr-2(icon @click="toggleSort()" :title="sortByRecent ? 'Sort by alphabetical sort' : 'Sort by most recent usage'")
      v-icon {{ sortByRecent ? 'mdi-history' : 'mdi-sort-alphabetical-variant' }}
    template(v-if="sortByRecent")
      h4.mp-section-title Recent Recipes
    template(v-else)
      h4.mp-section-title Recipes
      v-spacer
      v-text-field(prepend-icon="mdi-magnify" :value="recipeFilter" @input="updateRecipeFilter($event)" label="Filter by recipe name" clearable)

  .planning-day__recipes
    mp-recipe-card(class="planning-day__recipe-card"
                   v-for="recipe in displayedRecipes" :key="recipe._id"
                   :recipe="recipe"
                   @click.native="addRecipe(recipe)"
                  )
</template>

<script>
import fuzzy from 'fuzzy'
import { mapState } from 'vuex'

import { PlanningDay } from '@/api/models/PlanningDay'
import { Meals, RecipeUsage } from '@/api/models/RecipeUsage'
import MpRecipeCard from '@/components/MpRecipeCard'
import * as _ from '@/util'

export default {
  components: {
    MpRecipeCard
  },
  props: {
    date: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      lastChosenMeal: 'none',
      lastChosenServings: 1,
      recipeFilter: '',
      sortByRecent: false, // sort by name by default
      Meals
    }
  },
  computed: {
    planningDay () {
      return this.$store.state.library.planningDays.find(planningDay => planningDay.slug === this.date) || new PlanningDay({ date: this.date })
    },
    ...mapState({
      assignedRecipeUsages () {
        return this.planningDay.listRecipeUsages(this.$store.state)
      }
    }),
    displayedRecipes () {
      return this.sortByRecent ? this.$store.getters['library/recentRecipes'] : this.recipes
    },
    recipes () {
      const recipeNameFilter = _.normalize(this.recipeFilter).trim()

      return this.$store.state.library.recipes
        .filter(recipe => {
          if (recipeNameFilter === '') {
            return true
          }

          const results = fuzzy.filter(recipeNameFilter, [_.normalize(recipe.name)])
          const match = (results.length > 0)
          return match
        })
        .sort((a, b) => _.localeStringComparator(a.name, b.name))
    },
    defaultServings: {
      get () {
        return this.$store.state.library.planning.defaultServings
      },
      set (value) {
        this.$store.commit('library/updateDefaultServings', value)
      }
    },
    yearmonth () {
      return this.date.substr(0, 7)
    },
    dateLabel () {
      return _.capitalize(new Date(this.date).toLocaleString('en-en', {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
        year: 'numeric'
      }))
    },
    monthLabel () {
      return _.capitalize(new Date(this.date).toLocaleString('en-en', {
        month: 'long',
        year: 'numeric'
      }))
    },
    previousDayDate () {
      return new Date(new Date(this.date).getTime() - 24 * 3600000).toISOString().substr(0, 10)
    },
    previousDayLink () {
      return `/planning/${this.previousDayDate}`
    },
    nextDayDate () {
      return new Date(new Date(this.date).getTime() + 24 * 3600000).toISOString().substr(0, 10)
    },
    nextDayLink () {
      return `/planning/${this.nextDayDate}`
    }
  },
  methods: {
    async addRecipe (recipe) {
      const updatedPlanningDay = this.planningDay.clone().addRecipeUsage(new RecipeUsage({
        recipeId: recipe._id,
        servings: this.lastChosenServings,
        meal: this.lastChosenMeal
      }))

      const updatedRecipe = recipe.clone()
      updatedRecipe.lastUsage = new Date()

      await Promise.all([
        this.savePlanningDay(updatedPlanningDay),
        this.$api.updateDocument(updatedRecipe)
      ])
    },
    async updateRecipeUsageMeal (recipeUsageIndex, meal) {
      this.lastChosenMeal = meal
      const updatedPlanningDay = this.planningDay.clone()
      updatedPlanningDay.recipeUsages[recipeUsageIndex].meal = meal
      await this.savePlanningDay(updatedPlanningDay)
    },
    async updateRecipeUsageServings (recipeUsageIndex, servings) {
      this.lastChosenServings = servings
      const updatedPlanningDay = this.planningDay.clone()
      updatedPlanningDay.recipeUsages[recipeUsageIndex].servings = servings
      await this.savePlanningDay(updatedPlanningDay)
    },
    async removeRecipeUsage (recipeUsageIndex) {
      const updatedPlanningDay = this.planningDay.clone().removeRecipeUsage(recipeUsageIndex)
      await this.savePlanningDay(updatedPlanningDay)
    },

    async savePlanningDay (planningDay) {
      if (this.planningDay._id) {
        await this.$api.updateDocument(planningDay)
      } else {
        await this.$api.addDocument(planningDay)
      }
    },
    updateRecipeFilter (filter) {
      this.recipeFilter = filter
    },
    toggleSort () {
      this.sortByRecent = !this.sortByRecent
      // TODO save this setting for future usages
    }
  }
}
</script>

<style lang="stylus">
.planning-day
  display flex
  flex-direction column
  max-height 100%

  &__recipe-card
    cursor pointer
    height 150px

    @media (max-width 600px)
      height 200px

  &__current-recipes
    overflow auto
    min-height 135px
    max-height 30vh
    padding-bottom 1px // hide the scrollbar at low height
    margin-right -10px

  &__recipes
    display grid
    grid-template-columns repeat(1, 1fr)
    grid-gap 1em
    overflow auto
    margin-right -10px
    padding-right 10px
    padding-top 4px
    padding-bottom 4px // bug: the overflow hides the bottom padding

    @media (min-width 600px)
      grid-template-columns repeat(2, 1fr)

    @media (min-width 780px)
      grid-template-columns repeat(3, 1fr)

    @media (min-width 980px)
      grid-template-columns repeat(4, 1fr)

    @media (min-width 1080px)
      grid-template-columns repeat(5, 1fr)

    @media (min-width 1180px)
      grid-template-columns repeat(6, 1fr)

    @media (min-width 1280px)
      grid-template-columns repeat(7, 1fr)

    @media (min-width 1580px)
      grid-template-columns repeat(8, 1fr)

.recipe-usage
  flex 1
  display flex
  align-items: center

  &__servings
    min-width: 100px

  &__recipe-name
    min-width: 150px
    font-size: 1.2em

  &__meal
    padding-left .5em

    // override vuetify style
    .v-input--selection-controls
      margin-top 0
      padding-top 0
</style>
