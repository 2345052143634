<template lang="pug">
v-app
  .offline-banner(v-if="authenticated && !online")
    | You are offline.&nbsp;
    span.offline-banner__sync-message Synchronization will not work until you are connected to the internet.
    v-btn.ml-4(x-small @click="restartSynchronization()") Reconnect

  mp-alerts
  mp-update-notification

  router-view
</template>

<script>
import MpAlerts from '@/components/MpAlerts'
import MpUpdateNotification from '@/components/MpUpdateNotification'

export default {
  components: {
    MpAlerts,
    MpUpdateNotification
  },
  computed: {
    authenticated () {
      return this.$store.getters['account/authenticated']
    },
    online () {
      return this.$store.state.account.online
    }
  },
  methods: {
    async restartSynchronization () {
      await this.$api.restartSynchronization()
    }
  }
}
</script>

<style lang="stylus" scoped>
.offline-banner
  top 0
  left 0
  right 0
  height 2em
  line-height 2em
  text-align center
  background-color #ff8383
  font-weight 600
  z-index 1

  &__sync-message
    @media (max-width 800px)
      display none

</style>
