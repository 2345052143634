<template lang="pug">
.mp-app
  mp-menu

  .mp-app__content
    router-view
</template>

<script>
import MpMenu from '@/components/MpMenu'

export default {
  name: 'AuthLayout',
  components: {
    MpMenu
  }
}
</script>

<style lang="stylus">
.mp-app
  display flex
  height 100vh
  min-width 390px

  @media (max-width 1365px)
    flex-direction column

  &__content
    flex 1
    overflow auto
    max-height 100%
    margin-left 250px

    @media (max-width 1365px)
      margin-left 0
      margin-top 65px
</style>
