<template lang="pug">
.mp-menu
  img.mp-menu__logo(src="/img/logo.svg" alt="Meal Planner")

  v-menu(offset-y)
    template(v-slot:activator="{ on }")
      v-btn.mp-menu__account-btn(text v-on="on" :title="email")
        v-icon(left medium) mdi-account
        .mp-menu__account-btn__name {{ email }}
        v-icon(right) mdi-chevron-down

    v-list(dense)
      v-list-item(to="/preferences" title="Preferences")
        v-list-item-action
          v-icon mdi-cog
        v-list-item-content
          v-list-item-title Preferences

      v-list-item(to="/about" title="About")
        v-list-item-action
          v-icon mdi-information
        v-list-item-content
          v-list-item-title About

      v-list-item(@click="logout" title="Logout")
        v-list-item-action
          v-icon mdi-logout
        v-list-item-content
          v-list-item-title Logout

  v-list.mp-menu__links
    v-list-item(to="/planning" title="Planning")
      v-list-item-action
        v-icon(medium) mdi-calendar-month
      v-list-item-content
        v-list-item-title Planning

    v-list-item(to="/recipes" title="Recipes")
      v-list-item-action
        v-icon(medium) mdi-book-open
      v-list-item-content
        v-list-item-title Recipes

    v-list-item(to="/ingredients" title="Ingredients")
      v-list-item-action
        v-icon(medium) mdi-food-apple
      v-list-item-content
        v-list-item-title Ingredients

    v-list-item(to="/categories" title="Categories")
      v-list-item-action
        v-icon(medium) mdi-tag-multiple
      v-list-item-content
        v-list-item-title Categories

    v-list-item(to="/thingstobuy" title="Things to buy")
      v-list-item-action
        v-icon(medium) mdi-cart
      v-list-item-content
        v-list-item-title Things to buy

  img.mp-menu__logo-brand(src="/img/logo-brand.svg" alt="Meal Planner")
</template>

<script>
import { mapState } from 'vuex'

import events from '@/events'

export default {
  computed: {
    ...mapState('account', [
      'email'
    ])
  },
  created () {
    events.bus.$on('unauthenticated', this.logout)
  },
  destroyed () {
    events.bus.$off('unauthenticated', this.logout)
  },
  methods: {
    async logout () {
      await this.$api.logout()
      await this.$router.push('/login')
    }
  }
}
</script>

<style lang="stylus">
.mp-menu
  background-color #e5e5e5
  width 250px
  display flex
  flex-direction column
  border-right 1px solid #cccccc
  position fixed
  height 100%

  @media (max-width 980px)
    .v-list-item__title
      display none

    .v-list-item__action
      margin-right 0 !important

  @media (max-width 1365px)
    flex-direction row
    height 65px
    width 100%
    z-index 1
    border-right none
    border-bottom 1px solid #cccccc

  &__links
    background-color #e4e4e4 !important
    height 100%
    overflow-y auto
    padding 0

    @media (max-width 1365px)
      width 100%
      display flex
      justify-content center

    // vuetify 2.0 bug: we don't want the flex on list children
    a
      flex 0

  &__account-btn
    height 64px !important
    margin 0

    @media (max-width 1365px)
      height 100% !important
      order 1

    &__name
      overflow hidden
      text-overflow ellipsis

      @media (max-width 1120px)
        display none

  &__logo
    width 40px
    align-self center
    margin-left 30px

    @media (max-width 540px)
      margin-left .5em

    @media (min-width 1365px)
      display none

  &__logo-brand
    margin-top 8px
    height 50px
    width 50%
    align-self center

    @media (max-width 1365px)
      display none
</style>
