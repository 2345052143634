<template lang="pug">
.mp-page.column

  h1.mp-page__title Preferences

  h4.mp-section-title Sample recipe library

  div
    v-btn.ma-2(color="primary" @click="loadSampleLibrary()" title="Load sample library") Load sample library

  h4.mp-section-title Advanced

  p Here you can reset, import and export the library. Use with caution!
  v-btn.ma-2(color="primary" @click="exportLibrary" title="Export library") Export library
  input(ref="file" type="file" @change="onFilesChange" hidden)
  v-btn.ma-2(color="primary" @click="openImportLibraryPopup" title="Ajouter une pièce jointe") Import library

  v-btn.ma-2(color="error" @click="clearLibrary" title="Clear library") Clear library
</template>

<script>
import * as _ from '@/util'

export default {
  methods: {
    async clearLibrary () {
      await this.$api.clearLibrary()
    },
    async loadSampleLibrary () {
      await this.$api.loadSampleLibrary()
    },
    async exportLibrary () {
      const libraryDump = JSON.stringify(await this.$api.exportLibrary())
      const fileURL = URL.createObjectURL(
        new Blob([libraryDump], { type: 'application/json' })
      )
      _.downloadFile(fileURL, `mp-export-${new Date().toISOString().substring(0, 19)}.json`)
    },
    async openImportLibraryPopup () {
      this.$refs.file.click()
    },
    onFilesChange (e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }
      const reader = new FileReader()
      reader.addEventListener('loadend', async () => {
        await this.$api.importLibrary(reader.result)
        this.$ui.success('Library imported successfully')
      })
      reader.readAsText(files[0], 'utf-8')
      e.target.value = ''
    }
  }
}
</script>

<style lang="stylus">
</style>
